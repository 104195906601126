import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './app/jsdoc';

import RenderSelection from './app/containers/RenderSelection';
import Notifications from './app/containers/Notifications';
import * as CommInfoActions from './app/actions/comminfo';
import OnlineStatus from './app/components/OnlineStatus';
import Overview from './app/containers/Overview';
import Signup from './app/containers/Signup';
import configureStore from './app/store';
import Rfq from './app/containers/Rfq';
import App from './app/containers/App';

global.Main = class Main {
  constructor() {
    let MainComponent;
    switch (global.AppData.section) {
      case 'signup':
        MainComponent = Signup;
        break;

      case 'app':
        MainComponent = App;
        break;

      case 'overview':
        MainComponent = Overview;
        break;

      case 'rfq':
        MainComponent = Rfq;
        break;

      case 'render_selection':
        MainComponent = RenderSelection;
        break;

      default:
      // No default
    }

    // TODO: Replace global store usage with react-redux connect
    const store = configureStore();
    global.store = store;

    function render() {
      ReactDOM.render(
        <div className={`root-${global.AppData.section}`}>
          <Provider store={store}>
            <OnlineStatus />
            <Notifications />
            <MainComponent />
          </Provider>
        </div>,
        document.getElementById('main'),
      );
    }

    // TODO: See above, connect removes this need
    store.subscribe(render);
    // First render
    render();

    // Keep an eye on connectivity and unfinished saving
    global.addEventListener('online', this.onOnlineStatus);
    global.addEventListener('offline', this.onOnlineStatus);
    global.onbeforeunload = this.onBeforeUnload;
  }

  onOnlineStatus() {
    CommInfoActions.setOnlineStatus(navigator.onLine);
  }

  // Should only return if the warning should show
  // eslint-disable-next-line consistent-return
  onBeforeUnload() {
    if (store.getState().comminfo.requestCount > 0) {
      return global.gettext(
        "We're not done saving your changes, are you sure?",
      );
    }
  }
};
