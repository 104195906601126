import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as constants from '../constants';
import * as SelectionHelpers from '../helpers/selection';
import * as Utils from '../utils';
import Position from '../containers/Position';
import Selection from '../containers/Selection';

import GripAddon from './GripAddon';
import GripImage from './GripImage';
import GripLabel from './GripLabel';

/**
 * Get positions per component, lever, front and direction.
 *
 * @param {Array} allPositions - All available positions.
 * @param {Object} selections - Selections for the current grip (one side of
 *    left or right).
 * @param {Object} editSelections - Store state for editSelection.
 * @return {Array}
 */
const getPositions = (allPositions, selections, editSelections) => {
  let componentId = null;
  let positions = [];

  if (editSelections.dragging.id) {
    componentId = editSelections.dragging.id;
  } else if (editSelections.moving.id) {
    componentId = Utils.getById(selections, editSelections.moving.id).component;
  }

  if (componentId !== null) {
    positions = allPositions.filter(
      (position) =>
        position.component === componentId &&
        position.lever === editSelections.leverId &&
        position.is_front === editSelections.is_front &&
        position.direction ===
        (editSelections.dragging.direction
          ? editSelections.dragging.direction
          : editSelections.moving.direction),
    )[0].entities;
  }

  return positions;
};

/**
 * One side of a lever grip.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lever - Lever object.
 * @param {Object} props.is_front - If viewing the front of the grip.
 * @param {Object} [props.isInteractive] - If the grip should be interactive
 *   instead of just display the current state. Defaults to true.
 * @param {number} [props.highlightSelection] - ID of selection to highlight.
 * @return {Object} React element.
 */
export default function Grip(props) {
  const {
    highlightSelection,
    is_front,
    isInteractive,
    lever,
    onBaseToggle,
    onHandRestToggle,
    showSelectionFunctions,
  } = props;
  const storeState = global.store.getState();
  const { editSelections } = storeState;
  const selections = SelectionHelpers.getGripSelections(
    storeState.selections.entities,
    lever,
    is_front,
  );
  const side = is_front ? 'front' : 'back';
  const direction = constants.GRIP_STRING[lever.grip];
  const positions = isInteractive
    ? getPositions(storeState.positions, selections, editSelections)
    : [];
  const wrapClass = classnames(
    `grip-wrap grip-wrap-${side} grip-wrap-${direction}`,
    {
      'grip-interactive': isInteractive,
      'grip-static': !isInteractive,
    },
  );

  return (
    <div className={wrapClass}>
      <div className={`grip grip-${side} grip-${direction}`}>
        <GripImage
          is_front={is_front}
          color={lever.color}
          useJpeg={!is_front}
        />
        <div
          className={`component-grid component-grid-${side} component-grid-${constants.GRIP_STRING[lever.grip]
            }`}
        >
          {selections.map((selection) => (
            <Selection
              key={selection.pk}
              selection={selection}
              availablePositions={positions.length}
              grip={lever.grip}
              isInteractive={isInteractive}
              isHighlighted={selection.pk === highlightSelection}
              showFunction={showSelectionFunctions}
            />
          ))}
          {positions.map((position) => (
            <Position
              key={`${position.x}-${position.y}`}
              position={position}
              editSelections={editSelections}
            />
          ))}
        </div>
        {is_front && <GripLabel lever={lever} />}
      </div>
      {is_front && (
        <div
          className={`grip-addons grip-addons-${side} grip-addons-${direction}`}
        >
          <GripAddon
            name="hand-rest"
            isActive={lever.has_hand_rest}
            onToggle={onHandRestToggle}
            isInteractive={isInteractive}
          />
          <GripAddon
            name="base"
            isActive={lever.has_base}
            onToggle={onBaseToggle}
            isInteractive={isInteractive}
          />
        </div>
      )}
    </div>
  );
}
Grip.displayName = 'components/Grip';
Grip.propTypes = {
  highlightSelection: PropTypes.number,
  is_front: PropTypes.bool.isRequired,
  isInteractive: PropTypes.bool,
  lever: PropTypes.object.isRequired,
  onBaseToggle: PropTypes.func,
  onHandRestToggle: PropTypes.func,
  showSelectionFunctions: PropTypes.bool,
};
Grip.defaultProps = {
  highlightSelection: null,
  isInteractive: true,
  onBaseToggle: null,
  onHandRestToggle: null,
  showSelectionFunctions: false,
};
