import {
  LOAD_COMPONENTS,
  RECEIVE_COMPONENTS,
  RESET_COMPONENTS,
  RECEIVE_COMPONENTS_ERROR,
} from '../action-types';
import * as Utils from '../utils';

import * as PositionActions from './positions';

// Loading and fetching components

function loadComponents() {
  return {
    type: LOAD_COMPONENTS,
  };
}

function receiveComponents(json) {
  return {
    type: RECEIVE_COMPONENTS,
    components: json,
  };
}

function receiveComponentsError(error) {
  return {
    type: RECEIVE_COMPONENTS_ERROR,
    error,
  };
}

// Calculate positions after components has been received
function postRequestComponents(json) {
  return (dispatch) => {
    dispatch(receiveComponents(json));
    dispatch(PositionActions.calculatePositions());
  };
}

export function requestComponents() {
  return (dispatch) => {
    dispatch(loadComponents());

    return Utils.ajaxCall(
      '/api/components/',
      'GET',
      null,
      postRequestComponents,
      receiveComponentsError,
    );
  };
}

// Other actions

export function resetComponents() {
  return {
    type: RESET_COMPONENTS,
  };
}
