import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as StringHelpers from '../helpers/string';
import { isProjectLocked } from '../utils';

import Button from './forms/Button';
import {
  List,
  Item,
  ItemImage,
  ItemBody,
  ItemTitle,
  ItemLink,
  ItemText,
} from './ItemList';

/**
 * A list of accessories.
 *
 * Setting the `onRemove` prop assumes the list is that of added/selected
 * accessories, which may entail specific styling for that state. Vice versa
 * for `onAdd`.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.items - Accessory objects.
 * @param {Function} [props.onAdd] - Accessory select callback.
 * @param {Function} [props.onRemove] - Accessory remove callback.
 * @param {boolean} [props.showsSelected] - If the list displays selected
 *   accessories.
 * @param {boolean} [props.showInfo] - If accessory info text should be
 *   displayed.
 * @return {Function} React element.
 */
export default function AccessoryList(props) {
  const { items, onAdd, onRemove, showsSelected, showInfo } = props;

  // No null from stateless components yet
  if (!items.length) {
    return <div />;
  }

  const isLocked = isProjectLocked();
  const baseClass = 'accessory-list';
  const className = classnames(baseClass, {
    [`${baseClass}-added`]: onRemove,
    [`${baseClass}-available`]: onAdd,
  });

  return (
    <List className={className} isInverted={!!(onRemove || showsSelected)}>
      {items.map((accessory) => {
        const itemBaseClass = 'accessory-item';
        const itemClassName = classnames(itemBaseClass, {
          [`${itemBaseClass}-added`]: onRemove,
          [`${itemBaseClass}-available`]: onAdd,
        });

        let info;
        if (accessory.info && showInfo) {
          info = (
            <ItemText>
              {StringHelpers.lineBreaksToParagraphs(accessory.info)}
            </ItemText>
          );
        }

        let datasheet;
        if (accessory.datasheet) {
          datasheet = <ItemLink href={accessory.datasheet} />;
        }

        const controls = [];
        if (onAdd && !isLocked) {
          controls.push(
            <Button
              key={0}
              isSmall
              className={`${itemBaseClass}-add`}
              onClick={onAdd.bind(null, accessory.pk)}
            >
              {global.gettext('Add')}
            </Button>,
          );
        }
        if (onRemove && !isLocked) {
          controls.push(
            <Button
              key={1}
              isDanger
              className={`${itemBaseClass}-remove`}
              onClick={onRemove.bind(null, accessory.pk)}
            >
              {global.gettext('Remove')}
            </Button>,
          );
        }

        return (
          <Item key={accessory.pk} className={itemClassName}>
            <ItemImage src={accessory.image} alt="" />
            <ItemBody>
              <ItemTitle>{accessory.name}</ItemTitle>
              {datasheet}
              {info}
              {controls.length > 0 && (
                <div className={`${itemBaseClass}-controls`}>{controls}</div>
              )}
            </ItemBody>
          </Item>
        );
      })}
    </List>
  );
}
AccessoryList.displayName = 'components/AccessoryList';
AccessoryList.propTypes = {
  items: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  showsSelected: PropTypes.bool,
  showInfo: PropTypes.bool,
};
AccessoryList.defaultProps = {
  onAdd: null,
  onRemove: null,
  showsSelected: false,
  showInfo: true,
};
