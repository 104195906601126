import {
  VIEW_EDIT_LEVER,
  VIEW_PROJECT,
  SET_EDITING_SELECTION,
  RESET_EDITING_SELECTION,
} from '../action-types';

export function viewEditLever() {
  return {
    type: VIEW_EDIT_LEVER,
  };
}

export function viewProject() {
  return {
    type: VIEW_PROJECT,
  };
}

/**
 * Set a selection that is currently being edited.
 *
 * @param {number} selectionId - ID of the selection.
 */
export function setEditingSelection(selectionId) {
  return {
    type: SET_EDITING_SELECTION,
    id: parseInt(selectionId, 10),
  };
}

/**
 * Reset the currently edited selection.
 *
 * @param {number} [selectionId] - Optional ID if a specific selection is to
 *    be reset. Useful for asynchronus flow where something else may overwrite
 *    before the deletion is finished.
 * @return {Object} Action object.
 */
export function resetEditingSelection(selectionId = null) {
  return {
    type: RESET_EDITING_SELECTION,
    id: selectionId,
  };
}
