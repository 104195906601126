import React from 'react';

import MessageBox, { MessageBoxTitle } from '../components/MessageBox';
import { GridWrap, GridItem } from '../components/FlexGrid';
import { isProjectLocked } from '../utils';
import Icon from '../components/Icon';

/**
 * Display global messages.
 */
export default function GlobalMessages() {
  const baseClass = 'global-messages';
  const itemClass = 'global-message';

  return (
    <div className={baseClass}>
      {isProjectLocked() && (
        <MessageBox
          type="info"
          className={`${itemClass}-locked`}
          isPlain
          isLarge
        >
          <GridWrap isInline>
            <GridItem shrink>
              <Icon name="lock" />
            </GridItem>
            <GridItem>
              <MessageBoxTitle>
                {global.gettext('Request for quotation submitted')}
              </MessageBoxTitle>
              <p>
                {global.gettext(
                  'Your request has been sent and the project is now locked for further changes.',
                )}
              </p>
            </GridItem>
          </GridWrap>
        </MessageBox>
      )}
    </div>
  );
}
GlobalMessages.displayName = 'containers/GlobalMessages';
