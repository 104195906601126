/**
 * String helpers
 *
 * @module helpers/string
 */

import React from 'react';

/**
 * Replace placeholders in a string with values.
 *
 *   format("A %1 and a %2", "cat", "dog")
 *
 * The placeholders start at index 1.
 *
 * @param {string} str - The string to format.
 * @param {...Mixed} replacements - A replacement for each placeholder.
 * @return {string} The formatted string.
 */
export function format(str, ...replacements) {
  const pattern = new RegExp(`%([1-${replacements.length}])`, 'g');
  const replacer = (match, index) => replacements[index - 1];

  return str.replace(pattern, replacer);
}

/**
 * Uppercase the first letter of a string.
 *
 * @param {string} str - String to transform.
 * @return {string}
 */
export function upperCaseFirst(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Convert line breaks to `p` tags.
 *
 * Instead of also adding `br` tags, set `white-space: pre-line;` with CSS to
 * preserve remaining line breaks.
 *
 * @param {string} str - String to transform.
 * @return {Array} React elements.
 */
export function lineBreaksToParagraphs(str) {
  const parts = [];
  const p = str.split(/(?:\r?\n)[ \t]*(?:\r?\n)+/g);

  for (let i = 0, len = p.length; i < len; i += 1) {
    parts.push(<p key={i}>{p[i]}</p>);
  }

  return parts;
}

/**
 * Trim any trailing slashes from a string.
 *
 * @param {string} str - String to trim.
 * @return {string}
 */
export function trimTrailingSlash(str) {
  return str.replace(/[/\\]+$/, '');
}

/**
 * Get the last part of a path.
 *
 * @param {string} path - Path/URL.
 * @return {string}
 */
export function getLastInPath(path) {
  return trimTrailingSlash(path)
    .split(/[/\\]/)
    .pop();
}

/**
 * Build a string from parts, filtering out falsy values other than zero.
 *
 * @param {Array} parts - Parts to join.
 * @return {string}
 * @example
 *
 * const conditional = '';
 * buildString(['This is', conditional && `not ${conditional}`, ' included.']);
 * // => 'This is included'
 */
export function buildString(parts) {
  return parts.filter((part) => Boolean(part) || part === 0).join('');
}

/**
 * Unicode characters.
 *
 * @type {Object}
 */
export const characters = {
  ellipsis: '\u2026',

  // Actually a multiplication sign
  cross: '\u00D7',
};
