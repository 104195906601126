import React from 'react';
import PropTypes from 'prop-types';

import * as LeverActions from '../actions/levers';
import { characters } from '../helpers/string';
import Button from '../components/forms/Button';
import { isProjectLocked } from '../utils';
import * as constants from '../constants';
import Icon from '../components/Icon';

/**
 * Lever tools.
 *
 * Includes adding, removing, mirroring and resetting.
 *
 * @param {Object} lever - A Lever state object
 */
export default class LeverTools extends React.Component {
  static displayName = 'containers/LeverTools';

  static propTypes = {
    lever: PropTypes.object.isRequired,
    canMirror: PropTypes.bool,
  };

  static defaultProps = {
    canMirror: false,
  };

  /**
   * Handle activate lever click
   */
  handleActivateClick = () => {
    global.store.dispatch(
      LeverActions.initiateLeverActivation(this.props.lever.pk),
    );
  };

  /**
   * Handle deactivate lever click
   */
  handleDeactivateClick = () => {
    global.store.dispatch(
      LeverActions.initiateLeverDeactivation(this.props.lever.pk),
    );
  };

  /**
   * Handle mirror lever click
   */
  handleMirrorClick = () => {
    global.store.dispatch(LeverActions.mirrorLever(this.props.lever.pk));
  };

  /**
   * Handle reset lever click
   */
  handleResetClick = () => {
    global.store.dispatch(LeverActions.resetLever(this.props.lever.pk));
  };

  render() {
    const { lever, canMirror } = this.props;
    const mirrorTo = lever.grip === constants.LEFT ? 'right' : 'left';

    return (
      <ul className="lever-tools nav-bar">
        <li>
          <Button
            isSecondary
            disabled={!lever.is_active || !canMirror || isProjectLocked()}
            onClick={this.handleMirrorClick}
          >
            <Icon name={`mirror-to-${mirrorTo}`} />
            <span className="text">
              {global.gettext('Mirror grip') + characters.ellipsis}
            </span>
          </Button>
        </li>
        <li>
          <Button
            isSecondary
            disabled={!lever.is_active || isProjectLocked()}
            onClick={this.handleResetClick}
          >
            <Icon name="reset" />
            <span className="text">
              {global.gettext('Reset grip') + characters.ellipsis}
            </span>
          </Button>
        </li>
        <li>
          {!lever.is_active && (
            <Button
              isSecondary
              disabled={isProjectLocked()}
              onClick={this.handleActivateClick}
            >
              <Icon name="plus" />
              <span className="text">{global.gettext('Add grip')}</span>
            </Button>
          )}
          {lever.is_active && (
            <Button
              isSecondary
              disabled={isProjectLocked()}
              onClick={this.handleDeactivateClick}
            >
              <Icon name="cross" />
              <span className="text">{global.gettext('Remove grip')}</span>
            </Button>
          )}
        </li>
      </ul>
    );
  }
}
