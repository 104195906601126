import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { requestAccessories } from '../actions/accessories';
import { requestCANSettings } from '../actions/canSettings';
import { requestComponents } from '../actions/components';
import { requestLevers, requestLeverCANSettings } from '../actions/levers';
import { requestSelections } from '../actions/selections';
import { GRIP_NAME, COLOR_NAME, LABEL_NAME } from '../constants';
import { getSelectionSettingsData } from '../helpers/selection';
import { getById, getCANSettingLabel, getOrderedLevers } from '../utils';
import { format } from '../helpers/string';
import { CablesPreview } from '../components/Cables';
import { List, Item } from '../components/ItemList';
import { Panel, Group, Title } from '../components/PagePanel';
import AccessoryList from '../components/AccessoryList';
import DocumentLink from '../components/DocumentLink';
import Grip from '../components/Grip';
import GripInfo from '../components/GripInfo';

import Header from './Header';

/**
 * The base HTML/CSS class name for Overview components.
 *
 * @type {string}
 */
const BASE_CLASS = 'overview';

/**
 * Display a selected component with its chosen settings.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.selection - Selection data.
 * @param {Object} props.component - Component data.
 * @return {Function} React element.
 */
function SelectedComponent({ selection, component }) {
  const settings = getSelectionSettingsData(selection, component);
  const functionVal = selection.settings
    .filter((setting) => setting.name === 'function')
    .reduce((str, func) => func.value, '');

  const metaTexts = [
    {
      label: global.gettext('Function'),
      value: functionVal,
    },
    /* {
      label: global.gettext('Position'),
      value: selection.position_name
    } */
  ].filter((text) => !!text.value);

  return (
    <Item className={`${BASE_CLASS}-component`}>
      <h4 className={`${BASE_CLASS}-component-title`}>
        {`${component.name} (${selection.position_name})`}
      </h4>
      {component.datasheet && (
        <p className={`${BASE_CLASS}-component-datasheet`}>
          <DocumentLink url={component.datasheet} />
        </p>
      )}

      {metaTexts.length > 0 && (
        <p className={`${BASE_CLASS}-component-meta`}>
          {metaTexts.map((text, i) => {
            let sep;
            if (metaTexts.length > 1 && i > 0) {
              sep = <span className="sep">|</span>;
            }

            return (
              <span key={text}>
                {sep}
                <strong>{`${text.label}: `}</strong>
                {text.value}
              </span>
            );
          })}
        </p>
      )}

      {settings.length > 0 && (
        <div className={`${BASE_CLASS}-component-settings`}>
          <h5 className="h-plain">{global.gettext('Settings')}</h5>
          <ul>
            {settings.map((setting) => {
              if (
                setting.name !== 'function' &&
                setting.value !== null &&
                setting.value !== ''
              ) {
                return (
                  <li key={setting.name}>
                    <strong>{`${setting.label}: `}</strong>
                    <span>{setting.value}</span>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </Item>
  );
}
SelectedComponent.displayName = 'containers/Overview/SelectedComponent';
SelectedComponent.propTypes = {
  selection: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
};

/**
 * One side of a grip on the overview.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lever - Lever object.
 * @param {Array} props.selectionsData - Data for each selection.
 * @param {Object} props.selectionsData.component - The selection's component.
 * @param {Object} props.selectionsData.selection - The selection's data.
 * @param {boolean} props.is_front - If the grip is a front version.
 * @return {Function} React element.
 */
function OverviewGrip({ lever, selectionsData, is_front }) {
  let components;
  if (selectionsData.length) {
    components = selectionsData.map((data) => (
      <SelectedComponent
        key={data.selection.pk}
        selection={data.selection}
        component={data.component}
      />
    ));
  } else {
    components = (
      <p className={`${BASE_CLASS}-no-selected`}>
        {global.gettext('No components selected')}
      </p>
    );
  }

  return (
    <div
      className={`${BASE_CLASS}-grip ${BASE_CLASS}-grip--${
        is_front ? 'front' : 'back'
        }`}
    >
      <Title level={3} className={`${BASE_CLASS}-title h2`}>
        {is_front ? global.gettext('Front') : global.gettext('Back')}
        <span className={`${BASE_CLASS}-title-label`}>
          &mdash; {GRIP_NAME[lever.grip]}
        </span>
      </Title>

      <div className={`${BASE_CLASS}-grip-data`}>
        <div className={`${BASE_CLASS}-grip-components`}>
          <h3>{global.gettext('Components')}</h3>
          <List isInverted>{components}</List>
        </div>

        <div className={`${BASE_CLASS}-grip-lever`}>
          <Grip
            lever={lever}
            is_front={is_front}
            isInteractive={false}
            showSelectionFunctions
          />
        </div>
      </div>
    </div>
  );
}
OverviewGrip.displayName = 'containers/Overview/OverviewGrip';
OverviewGrip.propTypes = {
  lever: PropTypes.object.isRequired,
  selectionsData: PropTypes.array.isRequired,
  is_front: PropTypes.bool.isRequired,
};

/**
 * Main overview container.
 */
class Overview extends React.Component {
  static displayName = 'containers/Overview/Overview';

  static propTypes = {
    accessories: PropTypes.arrayOf(PropTypes.object).isRequired,
    canSettings: PropTypes.object.isRequired,
    components: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    levers: PropTypes.arrayOf(PropTypes.object).isRequired,
    leverCANOptions: PropTypes.object.isRequired,
    requestAccessories: PropTypes.func.isRequired,
    requestCANSettings: PropTypes.func.isRequired,
    requestComponents: PropTypes.func.isRequired,
    requestLeverCANSettings: PropTypes.func.isRequired,
    requestLevers: PropTypes.func.isRequired,
    requestSelections: PropTypes.func.isRequired,
    selections: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  componentDidMount() {
    this.props.requestAccessories();
    this.props.requestCANSettings();
    this.props.requestComponents();
    this.props.requestLeverCANSettings();
    this.props.requestLevers();
    this.props.requestSelections();
  }

  /**
   * Get selection data for a lever grip.
   *
   * @param {Object} lever - The lever entity object.
   * @param {boolean} is_front - If the front of the lever is displayed.
   * @return {Array}
   */
  getSelectionData = (lever, is_front) =>
    (this.props.components.length
      ? this.props.selections
        .filter(
          (selection) =>
            selection.lever === lever.pk && selection.is_front === is_front,
        )
        .map((selection) => ({
          component: getById(this.props.components, selection.component),
          selection,
        }))
      : []);

  /**
   * Get accessory data for the accessory list.
   *
   * @param {Object} lever - The lever entity object.
   * @return {Array}
   */
  getAccessoryData = (lever) =>
    this.props.accessories.filter((accessory) =>
      lever.accessories.includes(accessory.pk),
    );

  render() {
    const { canSettings, isLoading, leverCANOptions } = this.props;
    const levers = getOrderedLevers(this.props.levers).filter(
      (lever) => lever.is_active,
    );
    const hasAnyCan = levers.some((lever) => lever.has_can);
    const loadingText = isLoading ? global.gettext('Loading overview') : null;
    const canSettingData = {
      baud_rate: global.gettext('Baud rate'),
      protocol: global.gettext('Protocol'),
    };
    return (
      <div className={BASE_CLASS}>
        <Header loadingText={loadingText} />

        <div className={`${BASE_CLASS}-main`}>
          {!isLoading && levers.length === 0 && (
            <p className={`${BASE_CLASS}-info`}>
              {global.gettext('There are no active levers')}
            </p>
          )}

          <div className={`${BASE_CLASS}-meta`}>
            <p>
              {isLoading && <span>&nbsp;</span>}
              {!isLoading && levers.length > 0 && (
                <React.Fragment>
                  <strong>
                    {format(global.gettext('Grip color: %1'), '')}
                  </strong>
                  {COLOR_NAME[levers[0].color]}
                </React.Fragment>
              )}
            </p>
          </div>

          {isLoading && <Panel className={`${BASE_CLASS}-placeholder`} />}

          {!isLoading && (
            <React.Fragment>
              {levers.map((lever) => {
                const accessories = this.getAccessoryData(lever);
                const leverCANSettingData = {
                  bus_termination: global.gettext('Bus termination'),
                  source_address: lever.has_base
                    ? global.gettext('Grip source address')
                    : global.gettext('Source address'),
                  source_address_base: lever.has_base
                    ? global.gettext('Base source address')
                    : null,
                };

                return (
                  <Panel key={lever.pk}>
                    <Group>
                      <Title className="h1">{GRIP_NAME[lever.grip]}</Title>

                      <div className={`${BASE_CLASS}-lever-data`}>
                        <p>
                          <strong>
                            {format(global.gettext('Label: %1'), '')}
                          </strong>
                          {LABEL_NAME[lever.label]}
                        </p>
                      </div>
                    </Group>

                    <Group>
                      <OverviewGrip
                        lever={lever}
                        selectionsData={this.getSelectionData(lever, true)}
                        is_front
                      />
                    </Group>

                    <Group>
                      <OverviewGrip
                        lever={lever}
                        selectionsData={this.getSelectionData(lever, false)}
                        is_front={false}
                      />
                    </Group>

                    <Group className={`${BASE_CLASS}-accessories`}>
                      <Title level={3} className={`${BASE_CLASS}-title`}>
                        {global.gettext('Accessories')}
                        <span className={`${BASE_CLASS}-title-label`}>
                          &mdash; {GRIP_NAME[lever.grip]}
                        </span>
                      </Title>
                      {accessories.length > 0 && (
                        <AccessoryList
                          items={accessories}
                          showsSelected
                          showInfo={false}
                        />
                      )}
                      {!accessories.length && (
                        <p className={`${BASE_CLASS}-no-selected`}>
                          {global.gettext('No accessories added')}
                        </p>
                      )}
                    </Group>

                    <Group className={`${BASE_CLASS}-cables`}>
                      <Title level={3} className={`${BASE_CLASS}-title`}>
                        {global.gettext('Cables')}
                        <span className={`${BASE_CLASS}-title-label`}>
                          &mdash; {GRIP_NAME[lever.grip]}
                        </span>
                      </Title>
                      <p>
                        <strong>
                          {format(global.gettext('Cable length: %1'), '')}
                        </strong>
                        {format('%1 mm', lever.cable_length)}
                      </p>
                      <CablesPreview cables={lever.cables} />
                    </Group>

                    {lever.has_can && (
                      <Group className={`${BASE_CLASS}-can-settings`}>
                        <Title level={3} className={`${BASE_CLASS}-title`}>
                          {global.gettext('CAN settings')}
                          <span className={`${BASE_CLASS}-title-label`}>
                            &mdash; {GRIP_NAME[lever.grip]}
                          </span>
                        </Title>
                        {Object.entries(leverCANSettingData)
                          .filter((obj) => Boolean(obj[1]))
                          .map((obj) => (
                            <p key={obj[0]}>
                              <strong>{obj[1]}:</strong>{' '}
                              {getCANSettingLabel(
                                // source_address_base uses the source_address
                                // options selection.
                                leverCANOptions[
                                obj[0].replace(
                                  'source_address_base',
                                  'source_address',
                                )
                                ],
                                lever.can_settings[obj[0]],
                              )}
                            </p>
                          ))}
                      </Group>
                    )}
                  </Panel>
                );
              })}
              {hasAnyCan && (
                <Panel>
                  <Group>
                    <Title className="h1">{global.gettext('Both')}</Title>
                    <GripInfo />
                  </Group>
                  <Group className={`${BASE_CLASS}-can-settings`}>
                    <Title level={3}>{global.gettext('CAN settings')}</Title>
                    {Object.entries(canSettingData).map((obj) => (
                      <p key={obj[0]}>
                        <strong>{obj[1]}:</strong>{' '}
                        {getCANSettingLabel(
                          canSettings.options[obj[0]],
                          canSettings.values[obj[0]],
                        )}
                      </p>
                    ))}
                  </Group>
                </Panel>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  accessories: state.accessories.entities,
  canSettings: state.canSettings,
  components: state.components.entities,
  isLoading:
    state.accessories.isFetching ||
    state.canSettings.isFetching ||
    state.components.isFetching ||
    state.levers.isFetching ||
    state.levers.isFetchingCANOptions ||
    state.selections.isFetching,
  levers: state.levers.entities,
  leverCANOptions: state.levers.canOptions,
  selections: state.selections.entities,
});

const mapDispatchToProps = {
  requestAccessories,
  requestCANSettings,
  requestComponents,
  requestLeverCANSettings,
  requestLevers,
  requestSelections,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Overview);
