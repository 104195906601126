import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Base class for `FlexGrid` components.
 *
 * @type {string}
 */
const BASE_CLASS = 'flex-grid';

/**
 * A flexbox item.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.grow - If the item should have flex-grow 1.
 * @param {boolean} props.shrink - If the item should have flex-shrink 1.
 * @return {Object} React element.
 */
export function GridItem(props) {
  const { grow, shrink, children, className, ...passProps } = props;
  const itemClass = `${BASE_CLASS}-item`;
  const cls = classnames(itemClass, className, {
    [`${itemClass}--grow`]: grow,
    [`${itemClass}--shrink`]: shrink,
  });

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
GridItem.displayName = 'components/FlexGrid/GridItem';
GridItem.propTypes = {
  children: PropTypes.node.isRequired,
  grow: PropTypes.bool,
  shrink: PropTypes.bool,
  className: PropTypes.string,
};
GridItem.defaultProps = {
  grow: false,
  shrink: false,
  className: '',
};

/**
 * A wrapper for flexbox items.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function GridWrap(props) {
  const {
    alignHorizontal,
    alignVertical,
    isInline,
    children,
    className,
    ...passProps
  } = props;
  const wrapClass = `${BASE_CLASS}-wrap`;

  const cls = classnames(wrapClass, className, {
    [`${wrapClass}--${alignHorizontal}`]: alignHorizontal,
    [`${wrapClass}--${alignVertical}`]: alignVertical,
    [`${wrapClass}--inline`]: isInline,
  });

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
GridWrap.displayName = 'components/FlexGrid/GridWrap';
GridWrap.propTypes = {
  alignHorizontal: PropTypes.oneOf(['left', 'center', 'right']),
  alignVertical: PropTypes.oneOf(['top', 'middle', 'bottom']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isInline: PropTypes.bool,
};
GridWrap.defaultProps = {
  alignHorizontal: null,
  alignVertical: null,
  className: '',
  isInline: false,
};
