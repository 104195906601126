import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Base class for `PagePanel` components.
 *
 * @type {string}
 */
const BASE_CLASS = 'page-panel';

/**
 * A title in a PagePanel.
 *
 * @param {Object} props - Component props.
 * @param {number} [props.level] - Heading level, 1-6.
 */
export function Title(props) {
  const { level, className, children, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-title`, className);
  const Tag = `h${level}`;

  return (
    <Tag {...passProps} className={cls}>
      {children}
    </Tag>
  );
}
Title.displayName = 'components/PagePanel/Title';
Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};
Title.defaultProps = {
  className: '',
  level: 2,
};

/**
 * A group in a PagePanel.
 *
 * @param {Object} props - Component props.
 */
export function Group(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-group`, className);

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
Group.displayName = 'components/PagePanel/Group';
Group.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Group.defaultProps = {
  className: '',
};

/**
 * A styled page section.
 *
 * @param {Object} props - Component props.
 * @param {boolean} [props.isHalf] - If the panel is half width.
 */
export function Panel(props) {
  const { children, className, isFull, isHalf, ...passProps } = props;
  const cls = classnames(BASE_CLASS, className, {
    [`${BASE_CLASS}-full`]: isFull,
    [`${BASE_CLASS}-half`]: isHalf,
  });

  return (
    <section {...passProps} className={cls}>
      {children}
    </section>
  );
}
Panel.displayName = 'components/PagePanel/Panel';
Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFull: PropTypes.bool,
  isHalf: PropTypes.bool,
};
Panel.defaultProps = {
  children: null,
  className: '',
  isFull: false,
  isHalf: false,
};

/**
 * A wrapper for multiple `PagePanel`s.
 *
 * @param {Object} props - Component props.
 */
export function Wrap(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-wrap`, className);

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
Wrap.displayName = 'components/PagePanel/Wrap';
Wrap.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Wrap.defaultProps = {
  className: '',
};
