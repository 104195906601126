import {
  LOAD_COMPONENTS,
  RECEIVE_COMPONENTS,
  RESET_COMPONENTS,
  RECEIVE_COMPONENTS_ERROR,
} from '../action-types';
import * as Utils from '../utils';

export const initialEntityState = {
  pk: null,
  blacklist: null,
  cables: [],
  datasheet: null,
  has_direction: false,
  image: {},
  name: '',
  settings: [],
  x_size: null,
  y_size: null,
};

export const initialState = {
  entities: [],
  isFetching: false,
  error: null,
};

export function components(state = initialState, action) {
  switch (action.type) {
    // Load components from endpoint
    case LOAD_COMPONENTS:
      return Utils.assign(state, {
        isFetching: true,
        error: null,
      });

    // Receive components from endpoint
    case RECEIVE_COMPONENTS:
      return Utils.assign(state, {
        entities: action.components,
        isFetching: false,
      });

    // Error loading components from endpoint
    case RECEIVE_COMPONENTS_ERROR:
      return Utils.assign(state, {
        error: action.error,
        isFetching: false,
      });

    // Clear all existing components
    case RESET_COMPONENTS:
      return Utils.assign(state, {
        entities: [],
      });

    default:
      return state;
  }
}
