import {
  ADD_REQUEST,
  REMOVE_REQUEST,
  CLEAR_REQUESTS,
  DISPLAY_SAVED,
  SET_ONLINE_STATUS,
} from '../action-types';

// Will hold the saved timeout id
let savedTimer = null;
// Default saved timeout
const savedTimeout = 1500;

function dispatchDisplaySaved(display) {
  return {
    type: DISPLAY_SAVED,
    displaySaved: display,
  };
}

/**
 * Show `saved` text and hide it after timeout
 *
 * @return {Function} - Thunk
 */
export function displaySaved() {
  return (dispatch) => {
    dispatch(dispatchDisplaySaved(true));

    if (global.AppData.environment === 'test') {
      dispatch(dispatchDisplaySaved(false));
    } else {
      savedTimer = setTimeout(
        () => dispatch(dispatchDisplaySaved(false)),
        savedTimeout,
      );
    }
  };
}

/**
 * Restart saved timer and add request to queue
 *
 * @return {Function} - Thunk
 */
export function addRequest() {
  return (dispatch) => {
    clearTimeout(savedTimer);
    // Make sure we don't display saved
    dispatch(dispatchDisplaySaved(false));
    dispatch({ type: ADD_REQUEST });
  };
}

function dispatchRemoveRequest() {
  return {
    type: REMOVE_REQUEST,
  };
}

/**
 * Removes request from request count
 *
 * Display saved when count is down to 0
 *
 * @return {Function} - Thunk
 */
export function removeRequest() {
  return (dispatch, getState) => {
    // Remove request
    dispatch(dispatchRemoveRequest());

    // Get fresh request count
    const count = getState().comminfo.requestCount;

    if (count === 0) {
      // Display saved
      dispatch(displaySaved());
    }
  };
}

/**
 * Clear all requests and don't show saved
 *
 * @return {Object}
 */
export function clearRequests() {
  return {
    type: CLEAR_REQUESTS,
  };
}

/**
 * Set online status
 *
 * @param {boolean} isOnline - If online or not.
 * @return {Object}
 */
export function setOnlineStatus(isOnline) {
  return {
    type: SET_ONLINE_STATUS,
    isOnline,
  };
}
