// Accessories
export const LOAD_ACCESSORIES = 'LOAD_ACCESSORIES';
export const RECEIVE_ACCESSORIES = 'RECEIVE_ACCESSORIES';
export const RECEIVE_ACCESSORIES_ERROR = 'RECEIVE_ACCESSORIES_ERROR';

// CAN settings
export const LOAD_CAN_SETTINGS = 'LOAD_CAN_SETTINGS';
export const RECEIVE_CAN_SETTINGS = 'RECEIVE_CAN_SETTINGS';
export const RECEIVE_CAN_SETTINGS_ERROR = 'RECEIVE_CAN_SETTINGS_ERROR';
export const CHANGE_CAN_SETTINGS = 'CHANGE_CAN_SETTINGS';
export const UPDATED_CAN_SETTINGS = 'UPDATED_CAN_SETTINGS';

// Comm info
export const DISPLAY_SAVED = 'DISPLAY_SAVED';
export const ADD_REQUEST = 'ADD_REQUEST';
export const REMOVE_REQUEST = 'REMOVE_REQUEST';
export const CLEAR_REQUESTS = 'CLEAR_REQUESTS';
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS';

// Components
export const LOAD_COMPONENTS = 'LOAD_COMPONENTS';
export const RECEIVE_COMPONENTS = 'RECEIVE_COMPONENTS';
export const RECEIVE_COMPONENTS_ERROR = 'RECEIVE_COMPONENTS_ERROR';
export const RESET_COMPONENTS = 'RESET_COMPONENTS';

// Selections
export const REGISTER_DRAGSTART = 'REGISTER_DRAGSTART';
export const REGISTER_MOVESTART = 'REGISTER_MOVESTART';
export const REGISTER_DRAGEND = 'REGISTER_DRAGEND';
export const REGISTER_DRAGOVER = 'REGISTER_DRAGOVER';
export const REGISTER_DRAGLEAVE = 'REGISTER_DRAGLEAVE';
export const REGISTER_DROP = 'REGISTER_DROP';
export const LOAD_LEVER = 'LOAD_LEVER';
export const RESET_EDIT_SELECTIONS = 'RESET_EDIT_SELECTIONS';

// Levers
export const LOAD_LEVERS = 'LOAD_LEVERS';
export const LOAD_LEVER_CAN_SETTINGS = 'LOAD_LEVER_CAN_SETTINGS';
export const RECEIVE_LEVERS = 'RECEIVE_LEVERS';
export const RECEIVE_LEVER = 'RECEIVE_LEVER';
export const RECEIVE_LEVER_CAN_SETTINGS = 'RECEIVE_LEVER_CAN_SETTINGS';
export const RECEIVE_LEVERS_ERROR = 'RECEIVE_LEVERS_ERROR';
export const RECEIVE_LEVER_CAN_SETTINGS_ERROR =
  'RECEIVE_LEVER_CAN_SETTINGS_ERROR';
export const CHANGE_LEVER_CAN_SETTINGS = 'CHANGE_LEVER_CAN_SETTINGS';
export const CHANGE_CABLE_OUT_LENGTH = 'CHANGE_CABLE_OUT_LENGTH';
export const CHANGE_LABEL = 'CHANGE_LABEL';
export const ADD_ACCESSORY = 'ADD_ACCESSORY';
export const REMOVE_ACCESSORY = 'REMOVE_ACCESSORY';
export const CLEAR_ACCESSORIES = 'CLEAR_ACCESSORIES';
export const CHANGE_COLOR = 'CHANGE_COLOR';
export const ACTIVATE_LEVER = 'ACTIVATE_LEVER';
export const DEACTIVATE_LEVER = 'DEACTIVATE_LEVER';
export const ACTIVATE_LEVER_BASE = 'ACTIVATE_LEVER_BASE';
export const DEACTIVATE_LEVER_BASE = 'DEACTIVATE_LEVER_BASE';
export const ACTIVATE_LEVER_HAND_REST = 'ACTIVATE_LEVER_HAND_REST';
export const DEACTIVATE_LEVER_HAND_REST = 'DEACTIVATE_LEVER_HAND_REST';
export const INIT_MIRROR_LEVER = 'INIT_MIRROR_LEVER';
export const MIRROR_LEVER = 'MIRROR_LEVER';
export const CANCEL_RESET_MIRROR = 'CANCEL_RESET_MIRROR';
export const INIT_RESET_LEVER = 'INIT_RESET_LEVER';
export const RESET_LEVER = 'RESET_LEVER';
export const CANCEL_RESET_LEVER = 'CANCEL_RESET_LEVER';

// Notifications
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

// Positions
export const REGISTER_POSITIONS = 'REGISTER_POSITIONS';

// Project
export const VIEW_EDIT_LEVER = 'VIEW_EDIT_LEVER';
export const VIEW_PROJECT = 'VIEW_PROJECT';
export const SET_EDITING_SELECTION = 'SET_EDITING_SELECTION';
export const RESET_EDITING_SELECTION = 'RESET_EDITING_SELECTION';

// RFQ
export const LOAD_RFQ = 'LOAD_RFQ';
export const RECEIVE_RFQ = 'RECEIVE_RFQ';
export const RECEIVE_RFQ_ERROR = 'RECEIVE_RFQ_ERROR';
export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE';
export const VALIDATE_FIELD = 'VALIDATE_FIELD';
export const VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS';
export const INIT_SUBMIT_FORM = 'INIT_SUBMIT_FORM';
export const AFTER_POST_FORM = 'AFTER_POST_FORM';
export const POST_FORM_ERROR = 'POST_FORM_ERROR';
export const UNSET_FIELD_EDITING = 'UNSET_FIELD_EDITING';

// Selections
export const LOAD_SELECTIONS = 'LOAD_SELECTIONS';
export const RECEIVE_SELECTIONS = 'RECEIVE_SELECTIONS';
export const RECEIVE_SELECTIONS_ERROR = 'RECEIVE_SELECTIONS_ERROR';
export const ADD_SELECTION = 'ADD_SELECTION';
export const RECEIVE_SELECTION = 'RECEIVE_SELECTION';
export const RECEIVE_SELECTION_ERROR = 'RECEIVE_SELECTION_ERROR';
export const REMOVE_SELECTION = 'REMOVE_SELECTION';
export const CHANGE_SELECTION_SETTINGS = 'CHANGE_SELECTION_SETTINGS';
export const FETCH_SELECTION = 'FETCH_SELECTION';
export const UNSET_SELECTION_EDITING = 'UNSET_SELECTION_EDITING';
export const RESET_LEVER_SELECTIONS = 'RESET_LEVER_SELECTIONS';

// Signup
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_EMAIL_ERROR = 'SET_EMAIL_ERROR';
export const SET_COMPANY = 'SET_COMPANY';
export const RECEIVE_PROJECT_ERROR = 'RECEIVE_PROJECT_ERROR';

// Uploads
export const ADD_UPLOAD_FILE = 'ADD_UPLOAD_FILE';
export const REMOVE_UPLOAD_FILE = 'REMOVE_UPLOAD_FILE';
export const CHANGE_UPLOAD_FILE_DATA = 'CHANGE_UPLOAD_FILE_DATA';
