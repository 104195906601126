import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { addNotification } from '../actions/notifications';
import { BUS_TERMINATION_VALUES_REQUIRE_BASE, GRIP_NAME, BLANK } from '../constants';
import { getLastInPath, characters, format } from '../helpers/string';
import { hasTopBackPosition } from '../helpers/selection';
import Spinner from '../components/Spinner';
import Logo from '../components/Logo';
import Icon from '../components/Icon';
import { getOrderedLevers } from '../utils';

/**
 * A loading message in the header.
 *
 * @param {Object} props - Component props.
 */
function HeaderLoading(props) {
  return (
    <p className="header-loading">
      <Spinner text={props.text + characters.ellipsis} />
    </p>
  );
}
HeaderLoading.displayName = 'containers/Header/HeaderLoading';
HeaderLoading.propTypes = {
  text: PropTypes.string.isRequired,
};

/**
 * The global page header with a logo.
 *
 * @param {string} [loadingText] - A loading message. Will have an ellipsis and
 *    a spinner added to it.
 */
class Header extends React.Component {
  static displayName = 'containers/Header';

  static propTypes = {
    addNotification: PropTypes.func.isRequired,
    children: PropTypes.node,
    leverCANOptions: PropTypes.object.isRequired,
    levers: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadingText: PropTypes.string,
    selections: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    children: null,
    loadingText: '',
  };

  getUrl = (path) => `/${global.AppData.uid}/${path}`;

  getActiveLevers = () =>
    getOrderedLevers(this.props.levers).filter((l) => l.is_active);

  hasAllTopPositions = () => {
    const activeLevers = this.getActiveLevers();
    return (
      activeLevers.filter((lever) =>
        hasTopBackPosition(this.props.selections, lever),
      ).length === activeLevers.length
    );
  };

  hasAllCANSourceAddresses = () => {
    const activeLevers = this.getActiveLevers().filter((l) => l.has_can);
    return (
      activeLevers.filter((lever) => {
        const opt = lever.can_settings;
        return lever.has_base
          ? opt.source_address && opt.source_address_base
          : opt.source_address;
      }).length === activeLevers.length
    );
  };

  checkIfValidColor = () =>
    (global.store.getState().levers.entities[0].color === BLANK)

  getInvalidCANSettingLevers = () => {
    const { leverCANOptions } = this.props;
    const sourceAddressOptions = leverCANOptions.source_address;
    const activeLevers = this.getActiveLevers();

    // TODO: This is the same validation as is done in LeverCANSettings, extract
    // from both and use that instead.
    return activeLevers.filter((lever) => {
      if (!lever.has_can) {
        return false;
      }

      const { can_settings: values, has_base } = lever;
      const otherLever = activeLevers.find((l) => l.pk !== lever.pk);

      if (
        !has_base &&
        BUS_TERMINATION_VALUES_REQUIRE_BASE.includes(values.bus_termination)
      ) {
        return true;
      }

      // Source address out of range
      if (
        !sourceAddressOptions.some((opt) => opt.value === values.source_address)
      ) {
        return true;
      }

      // Base source address out of range
      if (
        has_base &&
        !sourceAddressOptions.some(
          (opt) => opt.value === values.source_address_base,
        )
      ) {
        return true;
      }

      const otherLeverSourceAddresses = [
        otherLever ? otherLever.can_settings.source_address : '',
        otherLever && otherLever.has_base
          ? otherLever.can_settings.source_address_base
          : '',
      ].filter((val) => Boolean(val));

      // Duplicate source address
      if (
        (has_base && values.source_address === values.source_address_base) ||
        otherLeverSourceAddresses.includes(values.source_address)
      ) {
        return true;
      }

      // Duplicate base source address
      if (
        has_base &&
        (values.source_address_base === values.source_address ||
          otherLeverSourceAddresses.includes(values.source_address_base))
      ) {
        return true;
      }

      return false;
    });
  };

  isPathActive = (path) => {
    const basePath = global.location.pathname.replace(global.AppData.uid, '');
    return getLastInPath(basePath) === getLastInPath(path);
  };

  mailToOrder = () => {
    window.location.href = 'mailto:order@svab.se';
  }

  handleRfqClick = (e) => {
    const isPositionsInvalid = !this.hasAllTopPositions();
    const isMissingSourceAddress = !this.hasAllCANSourceAddresses();
    const invalidCANLevers = this.getInvalidCANSettingLevers();
    const noValidColorSelected = this.checkIfValidColor();
    if (
      isPositionsInvalid ||
      isMissingSourceAddress ||
      invalidCANLevers.length ||
      noValidColorSelected
    ) {
      e.preventDefault();
    }
    if (noValidColorSelected) {
      this.props.addNotification({
        type: 'warning',
        message: global.gettext(
          'Please select a color for the grips.',
        ),
        isDismissible: true,
      });
    }
    if (isPositionsInvalid) {
      this.props.addNotification({
        type: 'warning',
        message: global.gettext(
          'All grips must have a component placed in the top back position.',
        ),
        isDismissible: true,
      });
    }
    if (isMissingSourceAddress) {
      const hasBase = this.getActiveLevers().some((l) => l.has_base);
      this.props.addNotification({
        type: 'warning',
        message: format(
          global.gettext('All %1 using CAN must have a source address.'),
          hasBase ? global.gettext('nodes') : global.gettext('grips'),
        ),
        isDismissible: true,
      });
    } else if (invalidCANLevers.length) {
      const gripText =
        invalidCANLevers.length === 1
          ? format(
            global.gettext('the %1 grip'),
            GRIP_NAME[invalidCANLevers[0].grip],
          )
          : global.gettext('both grips');
      this.props.addNotification({
        type: 'warning',
        message: format(
          global.gettext(
            'There are invalid CAN settings on %1, check them before continuing.',
          ),
          gripText.toLowerCase(),
        ),
        isDismissible: true,
      });
    }
  };

  render() {
    const { loadingText } = this.props;
    const activeLevers = this.getActiveLevers();
    const hasActiveLevers = Boolean(activeLevers.length);

    const urls = [
      {
        path: '',
        label: global.gettext('Config'),
        isDisabled: false,
      },
      {
        path: 'overview/',
        label: global.gettext('Overview'),
        isDisabled: !hasActiveLevers,
      },
      {
        path: 'rfq/',
        label: [
          <Icon key={1} name="check" />,
          <span key={2}>{global.gettext('Request for quotation')}</span>,
        ],
        isButton: true,
        isDisabled: !hasActiveLevers,
        handleClick: this.handleRfqClick,
      },
    ];

    const contact = () => {
      const className =
        classnames('nav-item', {
          'nav-item-enabled': true,
          btn: true,
          'btn-base': true,
        });

      return (
        <div
          style={{
            cursor: "pointer"
          }}
          className={className}
          onClick={this.mailToOrder}
        >
          {global.gettext('Contact')}
        </div>
      );
    }

    return (
      <header className="header">
        <div className="header-inner">
          <div className="header-logo">
            <a href={this.getUrl('')}>
              <Logo />
            </a>
          </div>

          <div className="header-content">
            {Boolean(loadingText) && <HeaderLoading text={loadingText} />}

            {this.props.children}

            {urls.map((url) => {
              const isActive = this.isPathActive(url.path);
              const className =
                classnames('nav-item', {
                  'nav-item-current': isActive,
                  'nav-item-enabled': !url.isDisabled,
                  'nav-item-disabled': url.isDisabled,
                  btn: url.isButton && !isActive,
                  disabled: url.isButton && url.isDisabled,
                  'btn-base btn-inverted-colors': url.isButton && isActive,
                }) || undefined;

              if (isActive || url.isDisabled) {
                return (
                  <span key={url.path} className={className}>
                    {url.label}
                  </span>
                );
              }
              return (
                <a
                  key={url.path}
                  href={this.getUrl(url.path)}
                  className={className}
                  onClick={url.handleClick}
                >
                  {url.label}
                </a>
              );
            })}

            {contact()}

          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  leverCANOptions: state.levers.canOptions,
  levers: state.levers.entities,
  selections: state.selections.entities,
});

const mapDispatchToProps = {
  addNotification,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
