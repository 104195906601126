import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { initialRootState as projectInitialState } from './reducers/project';
import { initialRootState as signupInitialState } from './reducers/signup';
import { initialRootState as rfqInitialState } from './reducers/rfq';
import { conditionalMiddleware } from './middlewares/conditional';
import { debouncedMiddleware } from './middlewares/debounced';
import rootReducer from './reducers/main';

export default function configureStore() {
  let initialState = null;
  switch (global.AppData.section) {
    case 'signup':
      initialState = signupInitialState;
      break;

    case 'app':
      initialState = projectInitialState;
      break;

    case 'overview':
      initialState = projectInitialState;
      break;

    case 'rfq':
      initialState = rfqInitialState;
      break;

    default:
      initialState = {};
  }

  const composeEnhancers =
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(conditionalMiddleware, debouncedMiddleware, thunk),
    ),
  );

  return store;
}
