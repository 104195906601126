import { REGISTER_POSITIONS } from '../action-types';

export function positions(state = [], action) {
  switch (action.type) {
    case REGISTER_POSITIONS:
      return action.positions;

    default:
      return state;
  }
}
