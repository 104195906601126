import React from 'react';

import GripInfoLinks from './GripInfoLinks';

/**
 * General information about the grips.
 */
export default function GripInfo() {
  return (
    <div className="grip-info">
      <p>{global.gettext('Product and technical information')}</p>
      <GripInfoLinks />
    </div>
  );
}
GripInfo.displayName = 'components/GripInfo';
