import React from 'react';

import { format, characters } from '../helpers/string';
import { Wrap, Panel } from '../components/PagePanel';
import * as AccessoryActions from '../actions/accessories';
import * as CANSettingsActions from '../actions/canSettings';
import * as ComponentActions from '../actions/components';
import * as constants from '../constants';
import * as EditSelectionsActions from '../actions/editSelections';
import * as LeverActions from '../actions/levers';
import * as PositionActions from '../actions/positions';
import * as ProjectActions from '../actions/project';
import * as RfqActions from '../actions/rfq';
import * as SelectionActions from '../actions/selections';
import * as utils from '../utils';
import Button from '../components/forms/Button';
import GlobalPanel from '../components/GlobalPanel';
import GripColorSwitcher from '../components/GripColorSwitcher';
import Icon from '../components/Icon';
import LeverPanel from '../components/LeverPanel';
import Overlay from '../components/Overlay';

import GlobalMessages from './GlobalMessages';
import Header from './Header';

/**
 * The main project component.
 */
export default class Project extends React.Component {
  static displayName = 'containers/Project';

  /**
   * Fetch all required data on mount.
   */
  componentDidMount = () => {
    global.store.dispatch(AccessoryActions.requestAccessories());
    global.store.dispatch(CANSettingsActions.requestCANSettings());
    global.store.dispatch(LeverActions.requestLeverCANSettings());
    global.store.dispatch(RfqActions.requestRfq());

    Promise.all([
      global.store.dispatch(LeverActions.requestLevers()),
      global.store.dispatch(SelectionActions.requestSelections()),
      global.store.dispatch(ComponentActions.requestComponents()),
    ]).then(() => global.store.dispatch(PositionActions.calculatePositions()));
  };

  /**
   * Add/activate a disabled grip.
   *
   * @param {number} leverId - ID of lever to enable.
   */
  handleLeverAdd = (leverId) => {
    global.store.dispatch(LeverActions.initiateLeverActivation(leverId));
  };

  /**
   * Confirm lever reset.
   *
   * @param {number} leverId - ID of lever to reset.
   */
  handleResetConfirm = (leverId) => {
    global.store.dispatch(LeverActions.initiateLeverReset(leverId));
  };

  /**
   * Cancel lever reset.
   *
   * @param {number} leverId - ID of lever that is being reset.
   */
  handleResetCancel = (leverId) => {
    global.store.dispatch(LeverActions.cancelResetLever(leverId));
  };

  /**
   * Confirm lever mirroring.
   *
   * @param {number} leverId - ID of lever that is being mirrored.
   */
  handleMirrorConfirm = (leverId) => {
    global.store.dispatch(LeverActions.initiateLeverMirroring(leverId));
  };

  /**
   * Cancel lever mirroring.
   */
  handleMirrorCancel = () => {
    global.store.dispatch(LeverActions.cancelMirrorLever());
  };

  /**
   * Handle changing lever color. Change for all levers at once.
   *
   * @param {string} color - The new color.
   */
  handleChangeLeverColor = (color) => {
    global.store.dispatch(
      LeverActions.putColor(global.store.getState().levers.entities, color),
    );
  };

  /**
   * Go to lever edit screen to place components.
   *
   * @param {number} leverId - ID of lever to edit.
   * @param {boolean} is_front - If editing the front.
   */
  handleLeverEdit = (leverId, is_front) => {
    global.store.dispatch(ProjectActions.viewEditLever());
    global.store.dispatch(EditSelectionsActions.editLever(leverId, is_front));
  };

  /**
   * Handle changing the lever label.
   *
   * @param {number} leverId - ID of lever to change label for.
   * @param {string} e - Select change event.
   */
  handleLabelChange = (leverId, e) => {
    global.store.dispatch(LeverActions.putLabel(leverId, e.target.value));
  };

  /**
   * Change cable length.
   *
   * @param {number} leverId - ID of lever whose length is set.
   * @param {number} value - The cable length to set.
   */
  handleCableChange = (leverId, value) => {
    global.store.dispatch(LeverActions.putCableOutLength(leverId, value));
  };

  /**
   * Add or remove the lever base.
   *
   * @param {number} leverId - Lever ID/pk.
   * @param {Object|bool} eventOrState - Checkbox change event or active state.
   *   The same handler is used for a checkbox and a component.
   */
  handleBaseToggle = (leverId, eventOrState) => {
    let isActive = eventOrState;
    if (typeof eventOrState === 'object' && eventOrState.target) {
      isActive = eventOrState.target.checked;
    }
    const action = isActive
      ? LeverActions.initiateLeverBaseActivation
      : LeverActions.initiateLeverBaseDeactivation;
    global.store.dispatch(action(leverId));
  };

  /**
   * Add or remove the lever hand-rest.
   *
   * @param {number} leverId - Lever ID/pk.
   * @param {Object|bool} eventOrState - Checkbox change event or active state.
   *   The same handler is used for a checkbox and a component.
   */
  handleHandRestToggle = (leverId, eventOrState) => {
    let isActive = eventOrState;
    if (typeof eventOrState === 'object' && eventOrState.target) {
      isActive = eventOrState.target.checked;
    }
    const action = isActive
      ? LeverActions.initiateLeverHandRestActivation
      : LeverActions.initiateLeverHandRestDeactivation;
    global.store.dispatch(action(leverId));
  };

  /**
   * Change lever CAN bus termination setting.
   *
   * @param {number} leverId - Lever ID/pk.
   * @param {string} e - Radio change event.
   * @param {Object} option - The selected option object.
   */
  handleBusTerminationChange = (leverId, e, option) => {
    global.store.dispatch(
      LeverActions.putBusTermination(leverId, option.value),
    );
  };

  /**
   * Change lever CAN source address setting.
   *
   * @param {number} leverId - Lever ID/pk.
   * @param {string} e - Select change event.
   */
  handleSourceAddressChange = (leverId, e) => {
    global.store.dispatch(
      LeverActions.putSourceAddress(leverId, e.target.value),
    );
  };

  /**
   * Change lever base CAN source address setting.
   *
   * @param {number} leverId - Lever ID/pk.
   * @param {string} e - Select change event.
   */
  handleBaseSourceAddressChange = (leverId, e) => {
    global.store.dispatch(
      LeverActions.putBaseSourceAddress(leverId, e.target.value),
    );
  };

  /**
   * Change global CAN baud rate setting.
   *
   * @param {string} e - Radio change event.
   * @param {Object} option - The selected option object.
   */
  handleBaudRateChange = (e, option) => {
    global.store.dispatch(CANSettingsActions.putBaudRate(option.value));
  };

  /**
   * Change global CAN protocol setting.
   *
   * @param {string} e - Radio change event.
   * @param {Object} option - The selected option object.
   */
  handleProtocolChange = (e, option) => {
    global.store.dispatch(CANSettingsActions.putProtocol(option.value));
  };

  render() {
    const storeState = global.store.getState();
    const levers = utils.getOrderedLevers(storeState.levers.entities);
    const mirroring = utils.getById(levers, storeState.levers.mirroring.id);
    const canMirror = !levers.some((lever) => !lever.is_active);
    const isMirroring = storeState.levers.mirroring.isConfirmed;
    const isLoading =
      storeState.rfq.isFetching ||
      storeState.levers.isFetching ||
      storeState.selections.isFetching;
    const hasCan = levers.some((lever) => lever.has_can);
    const hasBase = levers.some((lever) => lever.has_base);
    const baseClass = 'project';

    // Get color from one lever, since they are treated the same
    let color = '';
    if (levers[0]) {
      color = levers[0].color;
    }

    let mirrorFrom = '';
    let mirrorTo = '';
    if (mirroring) {
      const left = constants.GRIP_NAME[constants.LEFT].toLowerCase();
      const right = constants.GRIP_NAME[constants.RIGHT].toLowerCase();
      mirrorFrom = mirroring.grip === constants.LEFT ? left : right;
      mirrorTo = mirroring.grip === constants.LEFT ? right : left;
    }

    let loadingText = '';
    if (isLoading) {
      loadingText = global.gettext('Loading levers');
    }

    const mirrorButtonText = isMirroring
      ? global.gettext('Mirroring') + characters.ellipsis
      : format(
        global.gettext('Mirror grip from %1 to %2'),
        mirrorFrom,
        mirrorTo,
      );

    return (
      <div className={`${baseClass}-edit`}>
        <Header loadingText={loadingText} />

        <GlobalMessages />

        <div className={`${baseClass}-meta wrap`}>
          <GripColorSwitcher
            currentColor={color}
            onColorChange={this.handleChangeLeverColor}
          />
        </div>

        <Wrap>
          {mirroring && (
            <Overlay className="lever-overlay lever-overlay--mirror">
              <p>
                <Icon name={`mirror-to-${mirrorTo}`} />
              </p>
              <h2>{global.gettext('Mirror grip')}</h2>
              <p>
                {format(
                  global.gettext(
                    'This will mirror all settings from %1 to %2. Current settings on the %2 will be overwritten, are you sure?',
                  ),
                  mirrorFrom,
                  mirrorTo,
                )}
              </p>
              <Button
                onClick={this.handleMirrorConfirm.bind(
                  this,
                  storeState.levers.mirroring.id,
                )}
                isLoading={isMirroring}
              >
                {mirrorButtonText}
              </Button>
              <Button onClick={this.handleMirrorCancel} isPlain>
                {global.gettext('Cancel')}
              </Button>
              <div
                className={`mirror-symbol mirror-${mirrorFrom}-to-${mirrorTo}`}
              />
            </Overlay>
          )}

          {isLoading &&
            !levers.length && [
              <Panel key={1} isHalf className="lever-panel-placeholder" />,
              <Panel key={2} isHalf className="lever-panel-placeholder" />,
            ]}

          {levers.map((lever) => (
            <LeverPanel
              key={lever.pk}
              lever={lever}
              isInactive={!lever.is_active}
              canMirror={canMirror}
              onLeverEdit={this.handleLeverEdit.bind(this, lever.pk)}
              onLabelChange={this.handleLabelChange.bind(this, lever.pk)}
              onCableChange={this.handleCableChange.bind(this, lever.pk)}
              onBaseToggle={this.handleBaseToggle.bind(this, lever.pk)}
              onHandRestToggle={this.handleHandRestToggle.bind(this, lever.pk)}
              onBaseSourceAddressChange={this.handleBaseSourceAddressChange.bind(
                this,
                lever.pk,
              )}
              onBusTerminationChange={this.handleBusTerminationChange.bind(
                this,
                lever.pk,
              )}
              onSourceAddressChange={this.handleSourceAddressChange.bind(
                this,
                lever.pk,
              )}
            >
              {!lever.is_active && (
                <Overlay className="lever-overlay lever-overlay--removed">
                  <p>
                    <Icon name="cross" />
                  </p>
                  <h2>{global.gettext('Grip removed')}</h2>
                  <p>
                    {global.gettext(
                      'This grip is currently removed and will not be included. All settings are still saved should you want to add it again.',
                    )}
                  </p>
                  <Button
                    onClick={this.handleLeverAdd.bind(this, lever.pk)}
                    disabled={storeState.rfq.submitted}
                  >
                    {global.gettext('Add grip')}
                  </Button>
                </Overlay>
              )}
              {lever.resetting && lever.resetting.isConfirmed === false && (
                <Overlay className="lever-overlay lever-overlay--reset">
                  <p>
                    <Icon name="reset" />
                  </p>
                  <h2>{global.gettext('Reset grip')}</h2>
                  <p>
                    {global.gettext(
                      'This will reset all settings for this grip and can not be undone, are you sure?',
                    )}
                  </p>
                  <Button
                    onClick={this.handleResetConfirm.bind(this, lever.pk)}
                  >
                    {global.gettext('Reset grip')}
                  </Button>
                  <Button
                    isPlain
                    onClick={this.handleResetCancel.bind(this, lever.pk)}
                  >
                    {global.gettext('Cancel')}
                  </Button>
                </Overlay>
              )}
            </LeverPanel>
          ))}

          <GlobalPanel
            hasCan={hasCan || hasBase}
            onBaudRateChange={this.handleBaudRateChange}
            onProtocolChange={this.handleProtocolChange}
          />
        </Wrap>
      </div>
    );
  }
}
