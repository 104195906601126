import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Spinner component.
 *
 * @param {Object} props - Component props.
 * @param {string} [props.text] - Text next to the spinner.
 * @param {string} [props.color] - Spinner color.
 * @return {Object} React element.
 */
export default function Spinner(props) {
  const { text, color } = props;
  const baseClass = 'spinner';
  const className = classnames(baseClass, props.className, {
    [`${baseClass}--${color}`]: color,
  });

  let output = <span className={className} />;
  if (text) {
    output = (
      <span className={`${baseClass}-wrap`}>
        <span className={`${baseClass}-text`}>{text}</span>
        <span className={className} />
      </span>
    );
  }

  return output;
}
Spinner.displayName = 'components/Spinner';
Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['dark', 'light', 'black', 'white', 'rainbow']),
  text: PropTypes.string,
};
Spinner.defaultProps = {
  className: '',
  color: null,
  text: null,
};
