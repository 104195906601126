import React from 'react';
import PropTypes from 'prop-types';

/**
 * A form input label with a space added to the label text.
 *
 * @param {Object} props - Component props.
 * @param {string} props.text - The label text.
 * @return {Function} React label element.
 */
export default function Label(props) {
  const { text, ...passProps } = props;
  return <label {...passProps}>{`${text}:`}&nbsp;</label>;
}
Label.propTypes = {
  text: PropTypes.string.isRequired,
};
