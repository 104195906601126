import {
  REGISTER_DRAGOVER,
  REGISTER_DRAGLEAVE,
  REGISTER_DRAGSTART,
  REGISTER_MOVESTART,
  REGISTER_DRAGEND,
  REGISTER_DROP,
  LOAD_LEVER,
  RESET_EDIT_SELECTIONS,
} from '../action-types';
import * as Utils from '../utils';

export const initialState = {
  entities: [],
  leverId: null,
  is_front: null,
  dragging: {},
  moving: {},
  active: null,
};

export function editSelections(state = initialState, action) {
  switch (action.type) {
    case LOAD_LEVER:
      return Utils.assign(state, {
        entities: action.components,
        leverId: action.leverId,
        is_front: action.is_front,
      });

    case RESET_EDIT_SELECTIONS:
      return Utils.assign(state, initialState);

    case REGISTER_DRAGOVER:
      return Utils.assign(state, {
        active: action.id,
      });

    case REGISTER_DRAGLEAVE:
      return Utils.assign(state, {
        active: null,
      });

    case REGISTER_DRAGSTART:
      return Utils.assign(state, {
        dragging: {
          id: action.id,
          direction: action.direction,
        },
      });

    case REGISTER_MOVESTART:
      return Utils.assign(state, {
        moving: {
          id: action.id,
          direction: action.direction,
        },
      });

    case REGISTER_DRAGEND:
      if (!state.active) {
        return Utils.assign(state, {
          dragging: {},
          moving: {},
        });
      }
      return state;

    case REGISTER_DROP:
      // if (state.dragging !== null) {
      // } else if (state.moving !== null) {
      //    // Move existing selection
      //    const selection = Utils.getById(state.selections, state.moving)
      //    const index = state.selections.indexOf(selection)
      //    selections = [
      //        ...state.selections.slice(0, index),
      //        Utils.assign(state.selections[index], {
      //            x: action.x,
      //            y: action.y
      //        }),
      //        ...state.selections.slice(index + 1)
      //    ]
      // }

      return Utils.assign(state, {
        active: null,
        dragging: {},
      });

    default:
      return state;
  }
}
