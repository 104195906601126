import React from 'react';

import * as SelectionActions from '../actions/selections';
import * as ComponentActions from '../actions/components';
import { characters, format } from '../helpers/string';
import * as LeverActions from '../actions/levers';
import Spinner from '../components/Spinner';
import Grip from '../components/Grip';
import * as utils from '../utils';

/**
 * Main app component.
 *
 * @return {Object} React element.
 */
export default class RenderSelection extends React.Component {
  static displayName = 'containers/RenderSelection';

  /**
   * Fetch all required data on mount.
   */
  componentDidMount = () => {
    global.store.dispatch(ComponentActions.requestComponents());
    global.store.dispatch(LeverActions.requestLevers());
    global.store.dispatch(SelectionActions.requestSelections());
  };

  render() {
    const selectionId = Number(global.AppData.pk);
    const storeState = global.store.getState();
    const baseClass = 'render-selection';

    if (
      storeState.levers.isFetching ||
      !storeState.selections.entities.length ||
      !storeState.levers.entities.length
    ) {
      return (
        <p className={`${baseClass}-loading`}>
          <Spinner />
          <span>{global.gettext('Loading') + characters.ellipsis}</span>
        </p>
      );
    }

    const selection = utils.getById(
      storeState.selections.entities,
      selectionId,
    );

    if (!selection) {
      return (
        <p className={`${baseClass}-not-found`}>
          {format(
            global.gettext('Selection with ID %1 not found'),
            selectionId,
          )}
        </p>
      );
    }

    const lever = utils.getById(storeState.levers.entities, selection.lever);

    return (
      <div className={baseClass}>
        <Grip
          lever={lever}
          is_front={selection.is_front}
          isInteractive={false}
          highlightSelection={selection.pk}
        />
      </div>
    );
  }
}
