import {
  LOAD_RFQ,
  RECEIVE_RFQ,
  CHANGE_FIELD_VALUE,
  UNSET_FIELD_EDITING,
  VALIDATE_FIELD,
  VALIDATE_ALL_FIELDS,
  INIT_SUBMIT_FORM,
  AFTER_POST_FORM,
} from '../action-types';
import { getFieldError } from '../actions/rfq';
import * as Utils from '../utils';

export const initialFieldState = {
  type: 'text',
  name: '',
  label: '',
  value: '',
  options: [],
  isRequired: true,
  error: null,
};

function getField(data) {
  return Utils.assign(initialFieldState, data);
}

export const initialState = {
  fields: [
    getField({
      name: 'first_name',
      label: global.gettext('First name'),
    }),
    getField({
      name: 'last_name',
      label: global.gettext('Last name'),
    }),
    getField({
      name: 'email',
      type: 'email',
      label: global.gettext('Email'),
    }),
    getField({
      name: 'company',
      label: global.gettext('Company'),
    }),
    getField({
      name: 'street',
      label: global.gettext('Street'),
    }),
    getField({
      name: 'zipcode',
      label: global.gettext('Zip code'),
    }),
    getField({
      name: 'city',
      label: global.gettext('City'),
    }),
    getField({
      name: 'country',
      label: global.gettext('Country'),
    }),
    getField({
      name: 'vat_tax_number',
      label: global.gettext('VAT/TAX Number (if available)'),
      isRequired: false,
    }),
    getField({
      name: 'quantity_left',
      type: 'number',
      label: global.gettext('Quantity - Left'),
      value: 0,
      isRequired: false,
    }),
    getField({
      name: 'quantity_right',
      type: 'number',
      label: global.gettext('Quantity - Right'),
      value: 0,
      isRequired: false,
    }),
    getField({
      name: 'yearly_quantity_left',
      type: 'number',
      label: global.gettext('Expected quantity per year - Left'),
      value: 0,
      isRequired: false,
    }),
    getField({
      name: 'yearly_quantity_right',
      type: 'number',
      label: global.gettext('Expected quantity per year - Right'),
      value: 0,
      isRequired: false,
    }),
    getField({
      name: 'application',
      label: global.gettext('Application/product'),
      isRequired: false,
    }),
    getField({
      name: 'customer_role',
      label: global.gettext('Role'),
      isRequired: false,
      type: 'select',
      options: [],
    }),
    getField({
      name: 'notes',
      type: 'textarea',
      isRequired: false,
      label: global.gettext('Notes'),
    }),
  ],
  error: null,
  submitted: false,
  isEditing: false,
  isSubmitting: false,
  isFetching: false,
};

export const initialRootState = {
  rfq: initialState,
};

function field(state, action) {
  if (state.name !== action.name) {
    return state;
  }

  switch (action.type) {
    // Save field data
    case CHANGE_FIELD_VALUE:
      return Utils.assign(state, {
        value: action.value,
        error: null,
      });

    // Set field error text
    case VALIDATE_FIELD:
      return Utils.assign(state, {
        error: action.error,
        isEditing: false,
      });

    default:
      return state;
  }
}

export function rfq(state = initialState, action) {
  switch (action.type) {
    // Set loading state
    case LOAD_RFQ:
      return Utils.assign(state, {
        isFetching: true,
      });

    // Update field values and disable loading state
    case RECEIVE_RFQ:
      // Don't overwrite field state if currently editing
      if (state.isEditing) {
        return Utils.assign(state, {
          isFetching: false,
        });
      }

      return Utils.assign(state, {
        fields: state.fields.map((obj) =>
          Utils.assign(obj, {
            value: action.json[obj.name],
          }),
        ),
        submitted: action.json.submitted,
        isFetching: false,
      });

    // Set submitting state
    case INIT_SUBMIT_FORM:
      return Utils.assign(state, {
        isSubmitting: true,
      });

    // Disable submitting state and set submitted state
    case AFTER_POST_FORM:
      return Utils.assign(state, {
        isSubmitting: false,
        submitted: action.json.submitted,
      });

    // Validate all form fields
    case VALIDATE_ALL_FIELDS:
      return Utils.assign(state, {
        fields: state.fields.map((obj) =>
          Utils.assign(obj, {
            error: getFieldError(obj.name, obj.value),
          }),
        ),
      });

    // Unset editing state
    case UNSET_FIELD_EDITING:
      return Utils.assign(state, {
        isEditing: false,
      });

    // Pass actions to a single field
    case CHANGE_FIELD_VALUE:
    case VALIDATE_FIELD:
      return Utils.assign(state, {
        isEditing: true,
        fields: state.fields.map((obj) => field(obj, action)),
      });

    default:
      return state;
  }
}
