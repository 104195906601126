import {
  RECEIVE_ACCESSORIES,
  LOAD_ACCESSORIES,
  RECEIVE_ACCESSORIES_ERROR,
} from '../action-types';
import * as Utils from '../utils';

function loadAccessories() {
  return {
    type: LOAD_ACCESSORIES,
  };
}

function receiveAccessories(json) {
  return {
    type: RECEIVE_ACCESSORIES,
    accessories: json,
  };
}

function receiveAccessoriesError(error) {
  return {
    type: RECEIVE_ACCESSORIES_ERROR,
    error,
  };
}

/**
 * Request accessories
 *
 * @return {Function}
 */
export function requestAccessories() {
  return (dispatch) => {
    dispatch(loadAccessories());

    Utils.ajaxCall(
      '/api/accessories/',
      'GET',
      null,
      receiveAccessories,
      receiveAccessoriesError,
    );
  };
}
