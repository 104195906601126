import {
  RECEIVE_ACCESSORIES,
  LOAD_ACCESSORIES,
  RECEIVE_ACCESSORIES_ERROR,
} from '../action-types';
import * as Utils from '../utils';

export const initialEntityState = {
  pk: null,
  name: '',
  image: '',
  info: null,
  datasheet: null,
};

export const initialState = {
  entities: [],
  isFetching: false,
  error: null,
};

export function accessories(state = initialState, action) {
  switch (action.type) {
    // Loading accessories from endpoint
    case LOAD_ACCESSORIES:
      return Utils.assign(state, {
        isFetching: true,
      });

    // Receiving accessories from endpoint
    case RECEIVE_ACCESSORIES:
      return Utils.assign(state, {
        entities: action.accessories,
        isFetching: false,
      });

    // Error loading accessories from endpoint
    case RECEIVE_ACCESSORIES_ERROR:
      return Utils.assign(state, {
        error: action.error,
        isFetching: false,
      });

    default:
      return state;
  }
}
