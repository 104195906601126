import {
  ADD_REQUEST,
  REMOVE_REQUEST,
  CLEAR_REQUESTS,
  DISPLAY_SAVED,
  SET_ONLINE_STATUS,
  RECEIVE_RFQ_ERROR,
} from '../action-types';
import * as Utils from '../utils';

export const initialState = {
  requestCount: 0,
  displaySaved: false,
  isOnline: true,
  error: false,
};

export function comminfo(state = initialState, action) {
  switch (action.type) {
    // Add one request
    case ADD_REQUEST:
      return Utils.assign(state, {
        requestCount: state.requestCount + 1,
      });

    // Remove one request
    case REMOVE_REQUEST:
      return Utils.assign(state, {
        requestCount: state.requestCount - 1,
      });

    // Clear all requests and don't display `saved`
    case CLEAR_REQUESTS:
      return Utils.assign(state, {
        requestCount: 0,
        displaySaved: false,
      });

    // Display `saved` or not
    case DISPLAY_SAVED:
      return Utils.assign(state, {
        displaySaved: action.displaySaved,
      });

    case RECEIVE_RFQ_ERROR:
      return Utils.assign(state, {
        error: true,
      });

    // If we're online or not
    case SET_ONLINE_STATUS:
      return Utils.assign(state, {
        isOnline: action.isOnline,
      });

    default:
      return state;
  }
}
