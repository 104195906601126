import React from 'react';
import PropTypes from 'prop-types';

import { isProjectLocked } from '../utils';
import * as constants from '../constants';

import FieldGroup from './forms/FieldGroup';

/**
 * A single color radio button.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - Input label.
 * @param {string} props.value - Input value.
 * @param {boolean} props.checked - Input checked attribute.
 * @param {Function} props.onChange - Radio change event handler.
 * @return {Object} React element.
 */
function Control(props) {
  const { label, value, onChange, checked } = props;
  return (
    <span className="radio-group">
      <input
        id={`grip-color-${value}`}
        type="radio"
        name="grip-color"
        disabled={isProjectLocked()}
        value={value}
        checked={checked}
        onChange={onChange.bind(null, value)}
      />
      <label htmlFor={`grip-color-${value}`}>{label}</label>
    </span>
  );
}
Control.displayName = 'components/GripColorSwticher/Control';
Control.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};
Control.defaultProps = {
  checked: false,
};

/**
 * Grip color switch controls.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.currentColor - The currently set color.
 * @param {Function} props.onColorChange - Color radio change event handler.
 *    Passes the radio value, i.e. the selected color.
 * @return {Object} React element.
 */
export default function GripColorSwticher(props) {
  const { currentColor, onColorChange } = props;
  return (
    <div className="grip-color-switcher">
      <FieldGroup labelId="grip-color-label">
        <strong className="grip-color-label" id="grip-color-label">
          {`${global.gettext('Grip color')}:`}
        </strong>
        <Control
          label={constants.COLOR_NAME[constants.BLANK]}
          value={constants.BLANK}
          checked={currentColor === constants.BLANK}
          onChange={onColorChange}
        />
        <Control
          label={constants.COLOR_NAME[constants.BLACK]}
          value={constants.BLACK}
          checked={currentColor === constants.BLACK}
          onChange={onColorChange}
        />
        <Control
          label={constants.COLOR_NAME[constants.GRAY]}
          value={constants.GRAY}
          checked={currentColor === constants.GRAY}
          onChange={onColorChange}
        />
      </FieldGroup>
    </div>
  );
}
GripColorSwticher.displayName = 'components/GripColorSwticher';
GripColorSwticher.propTypes = {
  currentColor: PropTypes.string.isRequired,
  onColorChange: PropTypes.func.isRequired,
};
