import {
  LOAD_PROJECT,
  RECEIVE_PROJECT_ERROR,
  SET_EMAIL,
  SET_EMAIL_ERROR,
  SET_COMPANY,
} from '../action-types';
import * as Utils from '../utils';

export const initialState = {
  isFetching: false,
  error: null,
  email: '',
  emailError: null,
  company: '',
};

export const initialRootState = {
  signup: initialState,
};

export function signup(state = initialState, action) {
  switch (action.type) {
    case LOAD_PROJECT:
      return Utils.assign(state, {
        isFetching: true,
        error: null,
        emailError: null,
      });

    case RECEIVE_PROJECT_ERROR:
      return Utils.assign(state, {
        isFetching: false,
        error: action.error,
      });

    case SET_EMAIL:
      return Utils.assign(state, {
        email: action.email,
        emailError: null,
      });

    case SET_EMAIL_ERROR:
      return Utils.assign(state, {
        emailError: action.error,
      });

    case SET_COMPANY:
      return Utils.assign(state, {
        company: action.company,
      });
    default:
      return state;
  }
}
