import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FieldGroup from './FieldGroup';
import FieldGroupLabel from './FieldGroupLabel';
import RadioGroupItem from './RadioGroupItem';

/**
 * A group of radio buttons.
 *
 * @param {Object} props - Component props.
 * @param {Array.<Object>} props.options - Data for all radio buttons.
 * @param {string} props.selected - Value for the selected radio button.
 * @param {string} props.name - Name attribute which links the radios.
 * @param {string} props.groupLabel - Label describing the radio group.
 * @param {boolean} props.groupLabelId - Unique ID for the group label, used
 *   for aria-describedby binding. Only required if the label is visible.
 * @param {boolean} [props.isLabelVisible] - If the group label should be
 *   visible. Defaults to true.
 */
export default function RadioGroup(props) {
  const {
    className,
    disabled,
    groupLabel,
    groupLabelId,
    isLabelVisible,
    name,
    onChange,
    options,
    selected,
    type,
    ...passProps
  } = props;
  const cls = classnames(`radio-group radio-group--${type}`, className, {
    disabled,
  });

  return (
    <FieldGroup
      {...passProps}
      className={cls}
      labelId={groupLabelId}
      label={isLabelVisible ? undefined : groupLabel}
    >
      {Boolean(groupLabel && isLabelVisible) && (
        <FieldGroupLabel id={groupLabelId}>{groupLabel}</FieldGroupLabel>
      )}
      {options.map((option) => (
        <RadioGroupItem
          key={option.value}
          name={name}
          option={option}
          checked={option.value === selected}
          disabled={disabled || option.disabled}
          onChange={onChange}
        />
      ))}
    </FieldGroup>
  );
}
RadioGroup.displayName = 'components/forms/RadioGroup';
RadioGroup.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  groupLabel: PropTypes.string.isRequired,
  groupLabelId: PropTypes.string,
  isLabelVisible: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  selected: PropTypes.string,
  type: PropTypes.oneOf(['segmented', 'plain']),
};
RadioGroup.defaultProps = {
  className: '',
  disabled: false,
  groupLabelId: undefined,
  isLabelVisible: true,
  selected: null,
  type: 'plain',
};
