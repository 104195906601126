import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../action-types';
import { initialEntityState } from '../reducers/notifications';
import { assign, generateUUID } from '../utils';

/**
 * Remove a notification.
 *
 * @param {string} notificationId - ID of the notification to remove.
 * @return {Object} The action object.
 */
export function removeNotification(notificationId) {
  return {
    type: REMOVE_NOTIFICATION,
    id: notificationId,
  };
}

/**
 * Clear all notifications.
 *
 * @return {Object} The action object.
 */
export function clearNotifications() {
  return {
    type: CLEAR_NOTIFICATIONS,
  };
}

/**
 * Add notification to state
 *
 * @param {Object} notification - The notification entity.
 * @return {Object} The action object.
 */
export function dispatchAddNotification(notification) {
  return {
    type: ADD_NOTIFICATION,
    notification,
  };
}

/**
 * Add a notification.
 *
 * A notification can be manually dismissible but by default they auto close
 * after a timeout.
 *
 * @param {Object} notification - The notification entity.
 * @return {Function} - Thunk.
 */
export function addNotification(notification) {
  return (dispatch) => {
    // Create notification object
    const newNotification = assign(initialEntityState, notification);

    // Create uuid
    newNotification.id = generateUUID();

    // Add notification
    dispatch(dispatchAddNotification(newNotification));

    if (!newNotification.isDismissible) {
      // Remove notification after timeout
      if (global.AppData.environment === 'test') {
        dispatch(removeNotification(newNotification.id));
      } else {
        setTimeout(
          () => dispatch(removeNotification(newNotification.id)),
          newNotification.timeout,
        );
      }
    }
  };
}
