import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Spinner from '../Spinner';

/**
 * Button in various styles.
 *
 * @param {Function} [onClick] - Click event handler. Is passed the original
 *    event, but with preventDefault called on it beforehand.
 * @param {string} [type] - Button type, defaults to `button`.
 * @param {boolean} [isSecondary] - Use secondary button style.
 * @param {boolean} [isSmall] - Make button smaller.
 * @param {boolean} [isPlain] - Use plain text button style.
 * @param {boolean} [isDanger] - Use danger button style.
 * @param {boolean} [isNeutral] - Use a minimal amount of styles, to reduce the
 *    need for overriding.
 * @param {boolean} [isLoading] - Show a loading spinner and disable the button.
 * @param {string} [spinnerColor] - Color of the spinner, see Spinner component.
 */
export default class Button extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isSecondary: PropTypes.bool,
    isSmall: PropTypes.bool,
    isPlain: PropTypes.bool,
    isDanger: PropTypes.bool,
    isNeutral: PropTypes.bool,
    isLoading: PropTypes.bool,
    spinnerColor: PropTypes.string,
  };

  static defaultProps = {
    type: 'button',
    onClick: null,
    className: '',
    disabled: false,
    isSecondary: false,
    isSmall: false,
    isPlain: false,
    isDanger: false,
    isNeutral: false,
    isLoading: false,
    spinnerColor: 'light',
  };

  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    const {
      onClick,
      type,
      isSecondary,
      isSmall,
      isPlain,
      isDanger,
      isNeutral,
      isLoading,
      disabled,
      spinnerColor,
      children,
      className,
      ...passProps
    } = this.props;
    let spinner;

    if (isLoading) {
      spinner = <Spinner color={spinnerColor} />;
    }

    if (this.props.onClick) {
      passProps.onClick = this.handleClick;
    }

    const cls =
      classnames(className, {
        'btn-secondary': isSecondary,
        'btn-small': isSmall,
        'btn-plain': isPlain,
        'btn-danger': isDanger,
        'btn-neutral': isNeutral,
        'btn-loading': isLoading,
      }) || undefined;

    return (
      // Doesn't understand variable
      // eslint-disable-next-line react/button-has-type
      <button
        {...passProps}
        className={cls}
        type={type}
        disabled={isLoading || disabled}
      >
        {spinner}
        {children}
      </button>
    );
  }
}
