import React from 'react';

import { Panel, Group } from '../components/PagePanel';
import * as SignupActions from '../actions/signup';
import Button from '../components/forms/Button';
import { characters } from '../helpers/string';
import Logo from '../components/Logo';
import * as utils from '../utils';

/**
 * Signup form.
 */
export default class Signup extends React.Component {
  static displayName = 'containers/Signup';

  /**
   * Set the email field value on change.
   *
   * @param {Object} e - The change event.
   */
  handleChange = (e) => {
    global.store.dispatch(SignupActions.setEmail(e.target.value));
  };

  handleChangeCompany = (e) => {
    global.store.dispatch(SignupActions.setCompany(e.target.value));
  };

  /**
   * Request a new project when submitting the form.
   *
   * @param {string} email - The email address from the field.
   * @param {Object} e - Click or submit event.
   */
  handleSubmit = (email, company, e) => {
    e.preventDefault();
    global.store.dispatch(SignupActions.requestProject(email, company));
  };

  render() {
    const { signup } = global.store.getState();
    const baseClass = 'signup';
    const buttonText = signup.isFetching
      ? global.gettext('Creating project') + characters.ellipsis
      : global.gettext('Create project');
    const handleSubmit = this.handleSubmit.bind(this, signup.email, signup.company);

    return (
      <div className={baseClass}>
        <Logo />

        <Panel>
          <Group>
            <h1 className={`${baseClass}-title h2`}>
              {global.gettext('L8 Joystick Configurator')}
            </h1>

            <p>
              {global.gettext(
                'Use this tool to configure and customize an SVAB L8 joystick for your specific use case.',
              )}
            </p>
            <p>
              {global.gettext(
                'Enter your email address to get started. We will send you an email with a secret link that you use to access your project',
              )}
            </p>
            <p>
              The configurator does not support touch screens. Please use a computer instead.
            </p>
            <div style={{ width: '100%' }}>
              <div style={{ margin: 'auto', width: 128, height: 117 }}>
                <img
                  src={utils.staticUrl('img/telefon-hand-kryss.png')}
                  alt="SVAB grip configurator"
                />
              </div>
            </div>

            <form action="" onSubmit={handleSubmit} noValidate>
              <div className={`${baseClass}-field`}>
                <label htmlFor="email">{`${global.gettext('Email')}: `}</label>
                <input
                  className={signup.emailError && 'input-error'}
                  type="email"
                  id="email"
                  value={signup.email}
                  onChange={this.handleChange}
                />
                {signup.emailError && (
                  <span className="field-error">{signup.emailError}</span>
                )}
              </div>
              <div className={`${baseClass}-field`}>
                <label htmlFor="email">{`${global.gettext('Company (optional)')}: `}</label>
                <input
                  type="text"
                  id="company"
                  value={signup.company}
                  onChange={this.handleChangeCompany}
                />
              </div>

              <Button
                type="submit"
                onClick={handleSubmit}
                isLoading={signup.isFetching}
                className={`${baseClass}-submit`}
              >
                {buttonText}
              </Button>
            </form>
          </Group>
        </Panel>
      </div>
    );
  }
}
