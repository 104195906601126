import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isProjectLocked } from '../utils';

import RadioGroup from './forms/RadioGroup';

/**
 * Global CAN settings form.
 *
 * @param {Object} props - Component props.
 */
export default function CANSettings(props) {
  const { onBaudRateChange, onProtocolChange } = props;
  const { canSettings } = global.store.getState();
  const { isFetching, options, values } = canSettings;
  const isLocked = isProjectLocked();

  const baudRateName = 'can-settings-baud-rate';
  const protocolName = 'can-settings-protocol';
  const sectionClassName = 'can-settings-section';

  return (
    <div className="can-settings">
      <RadioGroup
        className={classnames(
          sectionClassName,
          `${sectionClassName}--baud-rate`,
        )}
        groupLabel={`${global.gettext('Baud rate')}:`}
        onChange={onBaudRateChange}
        name={baudRateName}
        options={options.baud_rate}
        selected={values.baud_rate}
        disabled={isLocked || isFetching}
      />

      <RadioGroup
        className={classnames(
          sectionClassName,
          `${sectionClassName}--protocol`,
        )}
        groupLabel={`${global.gettext('Protocol')}:`}
        onChange={onProtocolChange}
        name={protocolName}
        options={options.protocol}
        selected={values.protocol}
        disabled={isLocked || isFetching}
      />
    </div>
  );
}
CANSettings.displayName = 'components/CANSettings';
CANSettings.propTypes = {
  onBaudRateChange: PropTypes.func.isRequired,
  onProtocolChange: PropTypes.func.isRequired,
};
