import {
  RECEIVE_CAN_SETTINGS,
  LOAD_CAN_SETTINGS,
  RECEIVE_CAN_SETTINGS_ERROR,
  CHANGE_CAN_SETTINGS,
  UPDATED_CAN_SETTINGS,
} from '../action-types';
import { debug } from '../helpers/debug';
import * as Utils from '../utils';

import { requestLeverCANSettings } from './levers';
import { addNotification } from './notifications';

/* -Load available and selected options
-----------------------------------------------------------------------------*/

function loadCANSettings() {
  return {
    type: LOAD_CAN_SETTINGS,
  };
}

function receiveCANSettings(json) {
  return {
    type: RECEIVE_CAN_SETTINGS,
    values: json.settings,
    options: json.options,
  };
}

function updatedCANSettings(json) {
  return {
    type: UPDATED_CAN_SETTINGS,
    values: json,
  };
}

function receiveCANSettingsError(error) {
  return {
    type: RECEIVE_CAN_SETTINGS_ERROR,
    error,
  };
}

/**
 * Request CAN settings and values.
 *
 * @return {Function}
 */
export function requestCANSettings() {
  return (dispatch) => {
    dispatch(loadCANSettings());

    Utils.ajaxCall(
      `/api/${global.AppData.uid}/can-settings/`,
      'GET',
      null,
      receiveCANSettings,
      receiveCANSettingsError,
    );
  };
}

/**
 * General ajax call to put data to CAN settings.
 *
 * @param {Object} body - Put body data
 * @param {Function} [successCallback] - Custom callback for success.
 * @param {Function} [errorCallback] - Custom callback for error.
 * @return {Promise}
 */
function putData(
  body,
  successCallback = receiveCANSettings,
  errorCallback = receiveCANSettingsError,
) {
  return Utils.ajaxCall(
    `/api/${global.AppData.uid}/can-settings/`,
    'PUT',
    body,
    successCallback,
    errorCallback,
  );
}

/* -Update settings
-----------------------------------------------------------------------------*/

function changeCANSettings(values) {
  return {
    type: CHANGE_CAN_SETTINGS,
    values,
  };
}

/**
 * Create a CAN setting update action
 *
 * @param {string} settingsKey - Key for the value to update.
 * @param {string} errorMessage - Message to display if the update fails.
 * @return {Function} - Function that takes the new setting value and returns
 *   a thunk.
 */
function makeSettingsUpdater(settingsKey, errorMessage) {
  return (value) => (dispatch, getState) => {
    const current = getState().canSettings.values[settingsKey];
    dispatch(changeCANSettings({ [settingsKey]: value }));

    function settingsUpdateOk(json) {
      return () => {
        dispatch(updatedCANSettings(json));
        // Changing protocol affects available source address
        if (settingsKey === 'protocol') {
          dispatch(requestLeverCANSettings());
        }
      };
    }

    function settingsUpdateError(err) {
      return () => {
        debug(`CAN ${settingsKey} update error`, err);
        dispatch(addNotification({ message: errorMessage }));
        // Restore previous value
        dispatch(changeCANSettings({ [settingsKey]: current }));
      };
    }

    putData({ [settingsKey]: value }, settingsUpdateOk, settingsUpdateError);
  };
}

/**
 * Put CAN baud rate.
 *
 * @param {string} value - The selected value.
 * @return {Function} - Thunk
 */
export const putBaudRate = makeSettingsUpdater(
  'baud_rate',
  global.gettext('Could not set baud rate'),
);

/**
 * Put CAN protocol.
 *
 * @param {string} value - The selected value.
 * @return {Function} - Thunk
 */
export const putProtocol = makeSettingsUpdater(
  'protocol',
  global.gettext('Could not set CAN protocol'),
);
