import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
} from '../action-types';

export const initialEntityState = {
  id: null,
  type: 'error',
  message: '',
  isDismissible: false,
  timeout: 5000,
};

export const initialState = [];

export function notifications(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [...state, action.notification];

    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.id);

    case CLEAR_NOTIFICATIONS:
      return initialState;

    default:
      return state;
  }
}
