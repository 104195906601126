import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from './Icon';

/**
 * A link with a document icon that opens in a new window.
 *
 * @param {Object} props - Component props.
 * @param {string} props.url - The document URL.
 * @param {string} [props.text] - The link text. Defaults to 'Datasheet'.
 * @return {Object} React element.
 */
export default function DocumentLink(props) {
  const textClass = classnames('text', {
    visuallyhidden: props.hasHiddenText,
  });

  return (
    <a
      href={props.url}
      className="document-link"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="document-light" />
      <span className={textClass}>{props.text}</span>
    </a>
  );
}
DocumentLink.displayName = 'components/DocumentLink';
DocumentLink.propTypes = {
  hasHiddenText: PropTypes.bool,
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
};
DocumentLink.defaultProps = {
  hasHiddenText: false,
  text: global.gettext('Datasheet'),
};
