import React from 'react';
import PropTypes from 'prop-types';

import { Panel, Group, Title } from './PagePanel';
import CANSettings from './CANSettings';
import GripInfo from './GripInfo';

/**
 * Panel with 'global' settings, i.e. for both grips.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export default function GlobalPanel(props) {
  const { hasCan, onBaudRateChange, onProtocolChange } = props;

  return (
    <Panel className="global-panel" isFull>
      <Group>
        <Title>{global.gettext('Both')}</Title>
        <GripInfo />
      </Group>

      {hasCan && (
        <Group>
          <Title level={3}>{global.gettext('CAN settings')}</Title>
          <CANSettings
            onBaudRateChange={onBaudRateChange}
            onProtocolChange={onProtocolChange}
          />
        </Group>
      )}
    </Panel>
  );
}
GlobalPanel.displayName = 'components/GlobalPanel';
GlobalPanel.propTypes = {
  hasCan: PropTypes.bool,
  onBaudRateChange: PropTypes.func.isRequired,
  onProtocolChange: PropTypes.func.isRequired,
};
GlobalPanel.defaultProps = {
  hasCan: false,
};
