import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * A single `RadioGroup` item.
 */
export default class RadioGroupItem extends React.Component {
  static displayName = 'components/forms/RadioGroupItem';

  static propTypes = {
    name: PropTypes.string.isRequired,
    option: PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
    className: null,
  };

  /**
   * Change handler, pass the raw options object to the handler as a second
   * parameter.
   *
   * @param {Object} e - Change event.
   */
  handleChange = (e) => {
    this.props.onChange(e, this.props.option);
  };

  render() {
    const {
      name,
      option,
      onChange,
      checked,
      disabled,
      className,
      ...passProps
    } = this.props;
    const cls = classnames('radio-group-item', className, {
      'radio-group-item--selected': checked,
      'radio-group-item--disabled': disabled,
    });

    return (
      <label className={cls} {...passProps}>
        <input
          type="radio"
          name={name}
          value={option.value}
          checked={checked}
          disabled={disabled}
          onChange={this.handleChange}
        />
        <span className="text">{option.label}</span>
      </label>
    );
  }
}
