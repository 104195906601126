import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * A grouping element, alternative to fieldset.
 *
 * @param {Object} props - Component props.
 * @param {string} props.labelId - ID for `aria-labelledby` if there is a
 *    visible element to label the group. Legend alternative.
 * @param {string} props.label - Hidden label text for `aria-label`.
 * @return {Gunction} React label element.
 */
export default function FieldGroup(props) {
  const { labelId, label, children, className, ...passProps } = props;
  const cls = classnames('field-group', className);

  return (
    <div
      {...passProps}
      className={cls}
      role="group"
      aria-labelledby={labelId}
      aria-label={label}
    >
      {children}
    </div>
  );
}
FieldGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  labelId: PropTypes.string,
  label: PropTypes.string,
};
FieldGroup.defaultProps = {
  className: '',
  labelId: undefined,
  label: undefined,
};
