import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AutosizeInput from 'react-input-autosize';

/**
 * An input field that only accepts numbers.
 *
 * @param {string|number} value The input value.
 * @param {function} onChange Change handler. Is passed the input value.
 * @param {function} [onBlur] Blur handler. Is passed the input value and the
 *   current validity state (true or false).
 * @param {string} [type] Input type, `text` or `number`.
 * @param {string} [id] Input field ID.
 * @param {string} [name] Input field name.
 * @param {string} [className] Input field class name.
 * @param {string} [wrapClassName] Wrapping `div` class name.
 * @param {bool} [allowZero] If zero should be allowed. Defaults to false.
 * @param {bool} [allowNegative] If negative numbers should be allowed. Defaults
 *   to false.
 * @param {bool} [allowDecimals] If decimal numbers should be allowed. Defaults
 *   to false.
 * @param {bool} [isAutoSize] If the input should auto size. Defaults to false.
 * @param {string} [errorTextEmpty] Error text for empty input.
 * @param {string} [errorTextInvalid] Error text for when the number is invalid.
 * @param {string} [errorTextNegative] Error text for when the number is
 *   negative, if applicable.
 * @param {string} [errorTextZero] Error text for when the number is zero, if
 *   applicable.
 */
export default class NumberInput extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    allowDecimals: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    allowNegative: PropTypes.bool,
    allowZero: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errorTextEmpty: PropTypes.string,
    errorTextInvalid: PropTypes.string,
    errorTextNegative: PropTypes.string,
    errorTextZero: PropTypes.string,
    id: PropTypes.string,
    isAutoSize: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['text', 'number']),
    wrapClassName: PropTypes.string,
  };

  static defaultProps = {
    allowDecimals: false,
    allowEmpty: false,
    allowNegative: false,
    allowZero: false,
    className: '',
    disabled: false,
    errorTextEmpty: global.gettext('There must be a value'),
    errorTextInvalid: global.gettext('Invalid value'),
    errorTextNegative: global.gettext('The value can not be negative'),
    errorTextZero: global.gettext('The value can not be zero'),
    id: null,
    isAutoSize: false,
    name: null,
    onBlur: null,
    type: 'text',
    wrapClassName: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      isValid: this.isValidNumber(props.value),
    };
  }

  /**
   * Check if a value is a valid `number` type.
   *
   * @param {mixed} value - The value to check.
   * @return {bool}
   */
  isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  /**
   * Get the current value as a float.
   *
   * @return {number}
   */
  getFloatValue = () => parseFloat(this.props.value);

  /**
   * Check if a value is a valid number.
   *
   * Valid in this case means if the value is a proper number, not if the
   * number is valid according to any validation rules like 'less than 50'.
   *
   * @param {mixed} value - The value to check.
   * @return {bool}
   */
  isValidNumber = (value) => {
    if (this.props.allowNegative) {
      return true;
    }

    value = parseFloat(value);
    if (!this.isNumber(value)) {
      return false;
    }

    if (this.props.allowZero) {
      return value >= 0;
    }
    return value > 0;
  };

  /**
   * Set value by calling the onChange prop handler.
   *
   * Doesn't really set a value, but assumes the onChange handler will do so
   * and pass in the new value prop as intended.
   *
   * @param {mixed} value - The value to set.
   */
  setValue = (value) => {
    let validChars = '0-9';

    // Period and comma allowed when allowing decimals
    if (this.props.allowDecimals) {
      validChars += '.';
      value = value.toString().replace(',', '.');
      const periods = value.match(/\./g);

      // Don't allow multiple periods
      if (periods && periods.length > 1) {
        return;
      }
    }

    // Hyphen allowed (though no real minus) when allowing negative numbers
    if (this.props.allowNegative) {
      validChars += '-';

      // Only allowed at the beginning of the string
      if (value.indexOf('-', 1) !== -1) {
        return;
      }
    }

    // Validate against valid characters
    const invalidRegex = new RegExp(`[^${validChars}]`);
    if (invalidRegex.test(value)) {
      return;
    }

    // Check if the resulting number is valid. Allow single punctuation that
    // has passed validation above, since going from an empty input to a
    // negative number requires a single hyphen as the value before the number
    // is entered, for instance.
    if (
      value !== '' &&
      value !== '-' &&
      value !== '.' &&
      !this.isValidNumber(value)
    ) {
      return;
    }

    return this.props.onChange(value);
  };

  /**
   * Set an initial base value.
   */
  setBaseValue = () => {
    this.setValue(this.props.allowZero ? 0 : 1);
  };

  /**
   * Increment value by one.
   */
  incrementValue = () => {
    this.setValue(this.getFloatValue() + 1);
  };

  /**
   * Decrement value by one.
   */
  decrementValue = () => {
    let value = this.getFloatValue() - 1;

    if (value < 0 && !this.props.allowNegative) {
      value = 0;
    }

    this.setValue(value);
  };

  /**
   * Set valid state when receiving a new value.
   *
   * @param {Object} newProps - The new props.
   */
  componentWillReceiveProps = (newProps) => {
    this.setState({
      isValid:
        (newProps.allowEmpty && String(newProps.value) === '') ||
        this.isValidNumber(newProps.value),
    });
  };

  /**
   * Focus the input field.
   */
  focus = () => {
    this.refs.input.focus();
  };

  /**
   * Set the input value on change.
   *
   * @param {Object} e - Input change event.
   */
  handleChange = (e) => {
    this.setValue(e.target.value);
  };

  /**
   * Trigger blur handler prop, if available.
   */
  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.value, this.state.isValid);
    }
  };

  /**
   * Increment and decrement with the arrow keys.
   *
   * @param {Object} e - Keydown event.
   */
  handleKeyDown = (e) => {
    if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') {
      return;
    }

    if (!this.isValidNumber(this.getFloatValue())) {
      this.setBaseValue();
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      this.incrementValue();
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      this.decrementValue();
    }
  };

  /**
   * Get a relevant error text depending on the error.
   *
   * @return {string}
   */
  getErrorText = () => {
    const floatVal = this.getFloatValue();
    let text;

    if (!this.props.allowEmpty && this.props.value.toString() === '') {
      text = this.props.errorTextEmpty;
    } else if (floatVal === 0) {
      text = this.props.errorTextZero;
    } else if (floatVal < 0) {
      text = this.props.errorTextNegative;
    } else {
      text = this.props.errorTextInvalid;
    }

    return text;
  };

  render() {
    const className = classnames(this.props.className, {
      error: !this.state.isValid,
    });
    const wrapClass = classnames(
      'number-input-wrap',
      this.props.wrapClassName,
      {
        'tooltip tooltip-show': !this.state.isValid,
      },
    );

    let tooltip;
    if (!this.state.isValid) {
      tooltip = this.getErrorText();
    }

    const inputProps = {
      type: this.props.type,
      className,
      id: this.props.id,
      name: this.props.name,
      value: this.props.value.toString(),
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      onKeyDown: this.handleKeyDown,
      disabled: this.props.disabled,
      ref: 'input',
    };

    let field;
    if (this.props.isAutoSize) {
      inputProps.minWidth = 15;
      field = <AutosizeInput {...inputProps} />;
    } else {
      field = <input {...inputProps} />;
    }

    // Wrap required for tooltip, since pseudo elements don't work on replaced
    // elements. Block element with `display` since AutosizeInput is wrapped
    // in a div.
    return (
      <div
        className={wrapClass}
        style={{ display: 'inline-block' }}
        data-tooltip={tooltip}
      >
        {field}
      </div>
    );
  }
}
