import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * A title/heading for a MessageBox.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function MessageBoxTitle(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames('message-box-title', className);

  return (
    <h2 {...passProps} className={cls}>
      {children}
    </h2>
  );
}
MessageBoxTitle.displayName = 'components/MessageBox/MessageBoxTitle';
MessageBoxTitle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
MessageBoxTitle.defaultProps = {
  className: '',
};

/**
 * Different types of status message boxes.
 *
 * @param {Object} props - Component props.
 * @param {string} props.type - The message type; `info`, `warning`, `error`
 *   or `success`.
 * @param {string} [props.title] - Heading text.
 * @param {string} [props.text] - Paragraph text.
 * @param {boolean} [props.isLight] - Lighter colors.
 * @param {boolean} [props.isPlain] - A cleaner box style.
 * @param {boolean} [props.isLarge] - A larger box.
 * @return {Object} React element.
 */
export default function MessageBox(props) {
  const {
    type,
    title,
    text,
    isLight,
    isPlain,
    isLarge,
    children,
    className,
    ...passProps
  } = props;
  const lightSuffix = isLight ? '--light' : '';

  const cls = classnames(
    `message-box message-box--${type}${lightSuffix}`,
    className,
    {
      'message-box--light': isLight,
      'message-box--plain': isPlain,
      'message-box--large': isLarge,
    },
  );

  return (
    <div {...passProps} className={cls}>
      {Boolean(title) && <MessageBoxTitle>{title}</MessageBoxTitle>}
      {Boolean(text) && <p>{text}</p>}
      {children}
    </div>
  );
}
MessageBox.displayName = 'components/MessageBox';
MessageBox.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'error', 'success']).isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  isLight: PropTypes.bool,
  isPlain: PropTypes.bool,
  isLarge: PropTypes.bool,
};
MessageBox.defaultProps = {
  title: '',
  text: '',
  className: '',
  children: null,
  isLight: false,
  isPlain: false,
  isLarge: false,
};
