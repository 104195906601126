export const CONDITIONAL = 'CONDITIONAL';
/**
 * Conditional action middleware.
 *
 * Runs actions when all conditions are met. Conditions returning false are
 * considered unmet and will stop the action flow. The condition function is
 * passed `getState` and `action` as parameters.
 *
 * Expects the action `type` to be the CONDITIONAL constant and
 * actions as either a `callback` function or an `action` object.
 *
 * - The `callback` function should be a thunk, i.e. it will receive `dispatch`
 *   and `getState` as parameters.
 * - The `action` object should be a regular redux action object.
 */
export function conditionalMiddleware({ dispatch, getState }) {
  return (next) => (action) => {
    let conditional;
    if (action.meta && action.meta.conditional) {
      conditional = action.meta.conditional;
    }

    if (
      typeof action === 'object' &&
      action.type === CONDITIONAL &&
      conditional &&
      Array.isArray(conditional.conditions)
    ) {
      const conditions = conditional.conditions.filter((condition) =>
        condition(getState, action),
      );

      // Check if any condition is unmet
      if (conditions.length < conditional.conditions.length) {
        return action;
      } else if (typeof conditional.callback === 'function') {
        return conditional.callback(dispatch, getState);
      } else if (typeof conditional.action === 'object') {
        return next(conditional.action);
      }
    }

    return next(action);
  };
}

/**
 * Check if the RFQ is submitted, whereby the project is considered locked.
 */
export function IS_PROJECT_EDITABLE(getState, action) {
  return !getState().rfq.submitted;
}
