import React from 'react';
// import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as Utils from '../utils';

import Button from './forms/Button';
import ClickOutside from './ClickOutside';
import Icon from './Icon';

/**
 * A floating box next to a trigger button. Sort of popup-ish.
 *
 * The dialog can be managed from the outside by setting a ref and caling
 * methods manually: `this.refs.toggle.close()`.
 */
export default class ToggleDialog extends React.Component {
  static propTypes = {
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array,
    ]).isRequired,
    cancelText: PropTypes.string,
    children: PropTypes.node.isRequired,
    extraClass: PropTypes.string,
    extraButtonClass: PropTypes.string,
    buttonTooltip: PropTypes.string,
    showIcon: PropTypes.bool,
    isNeutral: PropTypes.bool,
    isInline: PropTypes.bool,
    customIconName: PropTypes.string,
    customIconNameClose: PropTypes.string,
    iconPos: PropTypes.oneOf(['left', 'right']),
    doToggleButtonText: PropTypes.bool,
    disabled: PropTypes.bool,
    lazyChildren: PropTypes.bool,
    pos: PropTypes.oneOf(['top', 'bottom']),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    cancelText: global.gettext('Close'),
    extraClass: '',
    extraButtonClass: '',
    buttonTooltip: '',
    showIcon: false,
    isNeutral: false,
    isInline: false,
    customIconName: '',
    customIconNameClose: '',
    iconPos: 'right',
    doToggleButtonText: true,
    disabled: false,
    lazyChildren: false,
    pos: 'bottom',
    onOpen: null,
    onClose: null,
  };

  /**
   * Constructor.
   *
   * @param {Object} props - Component props.
   * @param {string} props.buttonText - Text for the trigger button.
   * @param {string} [props.cancelText] - Replacement for the default cancel
   *   text.
   * @param {string} [props.extraClass] - Extra class name(s) for the dialog
   *   and wrap.
   * @param {string} [props.extraButtonClass] - Extra class name(s) for the
   *   button.
   * @param {string} [props.buttonTooltip] - Tooltip to show on the button.
   *   Requires the tooltip class name via extraButtonClass.
   * @param {boolean} [props.showIcon] - Whether to show a dropdown arrow.
   *   Default false.
   * @param {boolean} [props.isNeutral] - If the button should have nautral
   *   styling.
   * @param {boolean} [props.isInline] - If the dialog should open inline
   *   instead of floating above (i.e. absolute positioning or not).
   * @param {string} [props.customIconName] - Name of a custom icon to use.
   * @param {string} [props.customIconNameClose] - Name of a custom icon to use
   *   as a close icon, i.e. when the dialog is open. Defaults to
   *   customIconName.
   * @param {string} [props.iconPos] - Icon position relative to text, left or
   *   right. Defaults to right.
   * @param {boolean} [props.doToggleButtonText] - Whether to show the
   *   cancelText when open.
   * @param {boolean} [props.disabled] - The `disabled` attribute for the
   *   button.
   * @param {boolean} [props.lazyChildren] - Whether the modal contents should
   *   be rendered when opened instead of when the component is mounted.
   *   Default false.
   * @param {string} [props.pos] - Open position, "top" or "bottom".
   *   Defaults to bottom.
   * @param {Function} [props.onOpen] - Callback for when the dialog is opened.
   * @param {Function} [props.onClose] - Callback for when the dialog is closed.
   */
  constructor(props) {
    super(props);
    this.refToggle = 'toggleButton';
    this.count = 0;

    this.state = { isOpen: false };
  }

  componentWillMount() {
    this.count = Utils.getGlobalCount();
  }

  isOpen = () => this.state.isOpen;

  isClosed = () => !this.state.isOpen;

  open = () => {
    this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  toggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  focusButton() {
    // This is disabled right now because I don't want to test this
    // DOM crap shit thing
    // ReactDOM.findDOMNode(this.refs[this.refToggle]).focus()
  }

  // The state setting and rendering isn't synchronous, so these callbacks will
  // run before the new render state. The close callback will therefore run
  // while the contents are still visible, while the open callback is moved to
  // the end of the execution queue with setTimeout.
  runCallbacks = () => {
    if (this.props.onClose && this.state.isOpen) {
      this.props.onClose();
    } else if (this.props.onOpen && !this.state.isOpen) {
      setTimeout(() => this.props.onOpen(), 0);
    }
  };

  /**
   * Open or close the dialog when clicking the trigger button.
   *
   * @param {Object} e - Click event.
   */
  handleButtonClick = (e) => {
    e.preventDefault();
    this.toggle();
    this.runCallbacks();
  };

  /**
   * Close the dialog when clicking outside it.
   *
   * @param {Object} e - Click event.
   * @param {string} id - Modal ID.
   */
  handleClickOutside = (e, id) => {
    // If the click ouside is from the toggle button, the dialog will close
    // in this callback then immediately open in the button callback.
    // Chrome and possibly others sets e.target to the span inside the button,
    // so let's also check the parent node.
    if (
      (e.target.nodeName === 'BUTTON' &&
        id === e.target.getAttribute('aria-controls')) ||
      (e.target.parentNode &&
        e.target.parentNode.nodeName === 'BUTTON' &&
        id === e.target.parentNode.getAttribute('aria-controls'))
    ) {
      return;
    }

    if (this.state.isOpen) {
      this.runCallbacks();
      this.close();
    }
  };

  /**
   * Close the modal when pressing Escape.
   *
   * @param {Object} e - Keydown event.
   */
  handleWrapKeyDown = (e) => {
    if (e.key === 'Escape') {
      if (this.state.isOpen) {
        e.stopPropagation();
      }
      this.runCallbacks();
      this.close();
      this.focusButton();
    }
  };

  render() {
    const baseClass = 'toggle-dialog';
    const { isOpen } = this.state;
    const {
      extraButtonClass,
      pos,
      extraClass,
      isInline,
      showIcon,
      customIconName,
      customIconNameClose,
      iconPos,
      lazyChildren,
    } = this.props;

    const buttonText =
      isOpen && this.props.doToggleButtonText
        ? this.props.cancelText
        : this.props.buttonText;

    // Classnames
    const buttonClass = classnames(`${baseClass}-trigger`, extraButtonClass, {
      'btn-neutral': this.props.isNeutral,
    });
    const wrapClass = classnames(`${baseClass}-wrap ${baseClass}-${pos}`, {
      [`${baseClass}-inline`]: isInline,
      [`${baseClass}-wrap-open`]: isOpen,
      [`${extraClass}-wrap`]: extraClass,
    });
    const modalClass = classnames(baseClass, extraClass, {
      [`${baseClass}-open`]: isOpen,
      [`${extraClass}-open`]: isOpen && extraClass,
      hidden: !isOpen,
    });
    const modalInnerClass = `${baseClass}-inner`;

    const id = `${baseClass}-${this.count}`;
    const ariaExpanded = isOpen ? 'true' : 'false';

    // Icon
    let toggleIcon;
    if (showIcon) {
      toggleIcon = isOpen ? (
        <Icon name="cross-dark" />
      ) : (
          <Icon name="arrow-down-dark" />
        );
    } else if (customIconName) {
      const customName =
        isOpen && customIconNameClose ? customIconNameClose : customIconName;
      toggleIcon = <Icon name={customName} />;
    }
    let leftIcon;
    let rightIcon;
    if (iconPos === 'left') {
      leftIcon = toggleIcon;
    } else {
      rightIcon = toggleIcon;
    }

    // Contents
    let contents = null;
    if (!lazyChildren || isOpen) {
      contents = this.props.children;
    }

    return (
      /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
      <div className={wrapClass} onKeyDown={this.handleWrapKeyDown}>
        <Button
          className={buttonClass}
          onClick={this.handleButtonClick}
          disabled={this.props.disabled}
          aria-controls={id}
          aria-expanded={ariaExpanded}
          data-tooltip={this.props.buttonTooltip}
          ref={this.refToggle}
        >
          {leftIcon}
          <span className={`${baseClass}-text`}>{buttonText}</span>
          {rightIcon}
        </Button>
        <ClickOutside
          className={modalClass}
          id={id}
          onClickOutside={this.handleClickOutside}
        >
          <div className={modalInnerClass}>{contents}</div>
        </ClickOutside>
      </div>
    );
  }
}
