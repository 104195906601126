/**
 * Selection helpers.
 *
 * @module helpers/selection
 */

import * as constants from '../constants';
import * as utils from '../utils';

import * as GridHelpers from './grid';

/**
 * Get settings data for display.
 *
 * @param {Object} selection - Selection object.
 * @param {Object} component - The selection's component.
 * @return {Array} Objects containing `name` and `value`.
 */
export function getSelectionSettingsData(selection, component) {
  const settings = [];

  component.settings.forEach((componentSetting) => {
    selection.settings.forEach((selectionSetting) => {
      if (selectionSetting.name === componentSetting.name) {
        const setting = {
          name: componentSetting.name,
          label: componentSetting.label,
          value: null,
        };

        switch (componentSetting.type) {
          case constants.TEXT:
            setting.value = selectionSetting.value;
            break;

          case constants.CHECKBOX:
            setting.value = global.gettext('Yes');
            break;

          case constants.RADIO: {
            const selectedOption = componentSetting.options.find(
              (opt) => opt.ident === selectionSetting.value,
            );
            setting.value = selectedOption.label;
            break;
          }

          default:
          // No default
        }

        settings.push(setting);
      }
    });
  });

  return settings;
}

/**
 * Get the component for a selection.
 *
 * @param {Object} selection - The selection entity object.
 * @return {Object} Component entity object.
 */
export function getComponent(selection) {
  return utils.getById(
    global.store.getState().components.entities,
    selection.component,
  );
}

/**
 * Get the X and Y size for a selection.
 *
 * @param {Object} selection - The selection entity object.
 * @return {Object|null} Has keys `x_size` and `y_size`.
 */
export function getSize(selection) {
  const component = getComponent(selection);

  if (!component) {
    return null;
  }

  return GridHelpers.getXYSizeByDirection(
    component.x_size,
    component.y_size,
    selection.direction,
  );
}

/**
 * Get position group for a selection on the back.
 *
 * @param {Object} selection - The selection entity object.
 * @param {string} grip - Which grip, left or right. Accepts both the literal
 *    string and the grip side constant.
 * @return {string|null} Back group 'ID'.
 */
export function getBackGridGroup(selection, grip) {
  if (selection.is_front) {
    return null;
  }

  const size = getSize(selection);

  if (!size) {
    return null;
  }

  if (grip.length === 2) {
    grip = constants.GRIP_STRING[grip];
  }

  return GridHelpers.getBackGridGroup(
    selection.x,
    selection.y,
    size.x_size,
    size.y_size,
    grip,
  );
}

/**
 * Get the 'most correct' component image available for a selection.
 *
 * @param {Object} selection - The selection entity object.
 * @param {string} grip - Which grip, left or right. Accepts both the literal
 *    string and the grip side constant.
 * @return {string}
 */
export function getImageUrl(selection, grip, hasSide = false) {
  const component = getComponent(selection);
  const gridGroup = getBackGridGroup(selection, grip);
  let defaultImageKey = constants.DIRECTION_IMAGE[selection.direction];
  if (hasSide) defaultImageKey = utils.addSideSpecific(defaultImageKey, grip);

  const backImageKey = gridGroup ? `back_${gridGroup}` : '';
  const colorSetting = utils.getByAttribute(
    selection.settings,
    'name',
    'color',
  );
  const colorSettingLED = utils.getByAttribute(
    selection.settings,
    'name',
    'led_color_button',
  );
  const colorLED = utils.getByAttribute(
    selection.settings,
    'name',
    'led',
  );
  const selectedColor =
    colorSetting && colorSetting.value ? colorSetting.value : '';
  const selectedColorLED =
    colorSettingLED && colorSettingLED.value ? colorSettingLED.value : '';
  const selectedLED =
    colorLED && colorLED.value ? colorLED.value : '';

  let imageUrl = component.image[defaultImageKey];

  if (
    selectedColor &&
    backImageKey &&
    component.image[`${backImageKey}_${selectedColor}`]
  ) {
    imageUrl = component.image[`${backImageKey}_${selectedColor}`];
  } else if (
    selectedColorLED &&
    backImageKey &&
    component.image[`${backImageKey}_${selectedColorLED}`]
  ) {
    imageUrl = component.image[`${backImageKey}_${selectedColorLED}`];
  } else if (backImageKey && component.image[backImageKey]) {
    imageUrl = component.image[backImageKey];
  } else if (
    selectedColor &&
    component.image[`${defaultImageKey}_${selectedColor}`]
  ) {
    imageUrl = component.image[`${defaultImageKey}_${selectedColor}`];
  } else if (
    selectedColorLED &&
    selectedLED &&
    component.image[`${defaultImageKey}_${selectedColorLED}_${selectedLED}`]
  ) {
    imageUrl = component.image[`${defaultImageKey}_${selectedColorLED}_${selectedLED}`]
  } else if (
    selectedColorLED &&
    component.image[`${defaultImageKey}_${selectedColorLED}`]
  ) {
    imageUrl = component.image[`${defaultImageKey}_${selectedColorLED}`];
  }
  return imageUrl;
}

/**
 * Get selections belonging to a specific grip.
 *
 * @param {Array} selections - All selection entity objects.
 * @param {Object} lever - The lever entity object.
 * @param {boolean} is_front - If getting the front grid selections.
 * @return {Array}
 */
export function getGripSelections(selections, lever, is_front) {
  return selections.filter(
    (selection) =>
      selection.lever === lever.pk && selection.is_front === is_front,
  );
}

/**
 * Check if the top position on the back has a selection.
 *
 * @param {Array} selections - All selection entity objects.
 * @param {Object} lever - The lever entity.
 * @return {boolean}
 */
export function hasTopBackPosition(selections, lever) {
  return (
    getGripSelections(selections, lever, false).filter(
      (selection) => getBackGridGroup(selection, lever.grip) === '1',
    ).length > 0
  );
}
