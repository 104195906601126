import {
  VIEW_EDIT_LEVER,
  VIEW_PROJECT,
  SET_EDITING_SELECTION,
  RESET_EDITING_SELECTION,
} from '../action-types';
import * as Utils from '../utils';

export const initialState = {
  view: 'project',
  editingSelection: null,
};

export const initialRootState = {
  project: initialState,
};

export function project(state = initialState, action) {
  switch (action.type) {
    case VIEW_EDIT_LEVER:
      return Utils.assign(state, {
        view: 'edit',
      });

    case VIEW_PROJECT:
      return Utils.assign(state, {
        view: 'project',
      });

    case SET_EDITING_SELECTION:
      return Utils.assign(state, {
        editingSelection: action.id,
      });

    case RESET_EDITING_SELECTION:
      if (action.id && action.id !== state.editingSelection) {
        return state;
      }

      return Utils.assign(state, {
        editingSelection: null,
      });

    default:
      return state;
  }
}
