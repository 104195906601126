import React from 'react';
import classnames from 'classnames';

import { characters } from '../helpers/string';

import Spinner from './Spinner';
import Icon from './Icon';

/**
 * Display info about current network activity.
 *
 * @return {Object} React element.
 */
export default function CommInfo() {
  const commInfo = global.store.getState().comminfo;

  let content;
  if (commInfo.requestCount > 0) {
    content = (
      <Spinner
        color="dark"
        text={`${global.gettext('Saving')}${characters.ellipsis}`}
      />
    );
  } else {
    content = (
      <span>
        <span className="text">{`${global.gettext('Saved')} `}</span>
        <Icon name="check-thin" />
      </span>
    );
  }

  const errorContent = commInfo.error ? (
    <div style={{ backgroundColor: '#ed404b', display: 'flex' }}>
      <div style={{ flex: 1 }} />
      <span className="text">{`${global.gettext('Error saving! Reloading can help fix the problem.')} `}</span>
      <div style={{ flex: 1 }} />
    </div>
  ) : null;

  const className = classnames('comm-info tooltip tooltip-left ', {
    'comm-info--saving': commInfo.requestCount > 0,
    'comm-info--saved': commInfo.displaySaved,
  });

  return (
    <div>
      {errorContent}
      <div
        className={className}
        data-tooltip={global.gettext('All changes are automatically saved')}
      >
        {content}
      </div>
    </div>
  );
}
