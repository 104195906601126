import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as SelectionActions from '../actions/selections';
import * as EditActions from '../actions/editSelections';
import * as Utils from '../utils';

/**
 * A position for a possible selection.
 *
 * @param {Object} position - Position entity object.
 * @param {Object} editSelections - Store state for editSelections.
 */
export default class Position extends React.Component {
  static displayName = 'containers/Position';

  static propTypes = {
    position: PropTypes.object.isRequired,
    editSelections: PropTypes.object.isRequired,
  };

  /**
   * Register selection on drop.
   *
   * @param {Object} e - The drop event.
   */
  handleDrop = (e) => {
    e.preventDefault();
    const { position, editSelections } = this.props;
    const component = Utils.getById(
      global.store.getState().components.entities,
      editSelections.dragging.id,
    );

    global.store.dispatch(
      SelectionActions.postSelection(
        editSelections.leverId,
        editSelections.dragging.id,
        editSelections.dragging.direction,
        editSelections.is_front,
        position.x,
        position.y,
        component.settings,
      ),
    );

    global.store.dispatch(EditActions.registerDrop());
  };

  /**
   * Set active position on drag enter.
   *
   * @param {Object} e - The dragenter event.
   */
  handleDragEnter = (e) => {
    e.preventDefault();

    // Hacky way to always let any leave event run before enter when going
    // from one position to another
    setTimeout(() => {
      global.store.dispatch(
        EditActions.registerDragOver(this.props.position.id),
      );
    }, 10);
  };

  /**
   * Cancel the dragover event to make drop possible.
   *
   * {@link http://www.quirksmode.org/blog/archives/2009/09/the_html5_drag.html}
   *
   * @param {Object} e - The dragover event.
   */
  handleDragOver = (e) => {
    e.preventDefault();
  };

  /**
   * Clear active position on drag leave.
   *
   * @param {Object} e - The dragleave event.
   */
  handleDragLeave = (e) => {
    e.preventDefault();
    global.store.dispatch(
      EditActions.registerDragLeave(this.props.position.id),
    );
  };

  render() {
    const { position, editSelections } = this.props;
    const { active } = editSelections;
    const p = position;
    const isActive = active !== null && active === p.id;
    const className = classnames(
      `grid-position size-x-${p.x_size} size-y-${p.y_size} pos-x-${p.x} pos-y-${
      p.y
      }`,
      {
        'grid-position-active': isActive,
      },
    );

    return (
      <div
        className={className}
        onDrop={this.handleDrop}
        onDragOver={this.handleDragOver}
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
      />
    );
  }
}
