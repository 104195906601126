/**
 * Component helpers.
 *
 * @module helpers/component
 */

import * as Utils from '../utils';

import * as GridHelpers from './grid';

/**
 * Detect if two positions collide by checking rectangle overlap.
 *
 * @param {GridPosition} rect1 - Position object.
 * @param {GridPosition} rect2 - Position object.
 * @return {boolean}
 */
function isPositionColliding(rect1, rect2) {
  return (
    rect1.x < rect2.x + rect2.x_size &&
    rect1.x + rect1.x_size > rect2.x &&
    rect1.y < rect2.y + rect2.y_size &&
    rect1.y_size + rect1.y > rect2.y
  );
}

/**
 * Get a component's blacklisted coordinates.
 *
 * Combines any blacklisted coordinates on the component with hardcoded ones.
 *
 * @param {Object} component - Component object.
 * @param {string} grip - Lever grip, left or right as constant or string
 * @param {boolean} is_front - True for front, false for back.
 * @return {Array} List of blacklisted coordinates.
 */
export function getComponentBlacklist(component, grip, is_front) {
  let blacklist = [];

  if (component.blacklist) {
    blacklist = is_front
      ? component.blacklist.coordinates.front
      : component.blacklist.coordinates.back;

    // Mirror when right
    if (Utils.isRightGrip(grip)) {
      blacklist = GridHelpers.getMirroredPositions(blacklist, is_front);
    }
  }

  // Merge component blacklist with hardcoded back blacklist
  if (!is_front) {
    blacklist = GridHelpers.getBackBlacklist(grip, blacklist);
  }

  return blacklist;
}

/**
 * Calculate possible component positions.
 *
 * Loops through x and y in grid and tries to place component. Component can be
 * hindered by a selection or blacklist entry.
 *
 * @param {number} x_size - Components x size.
 * @param {number} y_size - Components y size.
 * @param {Array} blacklist - Positions blacklist.
 * @param {Array} components - All components.
 * @param {Array} selections - List of selections.
 * @param {boolean} is_front - Use front grid or not.
 * @return {Array} List of possible positions.
 */
export function getComponentPositions(
  x_size,
  y_size,
  blacklist,
  components,
  selections,
  is_front,
) {
  // Maybe refactor this function some time
  /* eslint-disable no-restricted-syntax, no-labels, no-continue */
  const resolution = GridHelpers.GRID_RESOLUTION;
  const positions = [];
  let id = 0;
  let grid;
  if (is_front) {
    grid = GridHelpers.GRID_FRONT;
  } else {
    grid = GridHelpers.GRID_BACK;
  }

  // Loop X
  for (let x = 0; x <= grid.x; x += resolution) {
    if (x + x_size > grid.x) {
      // Out of grid
      continue;
    }

    // Loop Y
    yLoop: for (let y = 0; y <= grid.y; y += resolution) {
      if (y + y_size > grid.y) {
        // Out of grid
        continue;
      }

      const position = { id, x, y, x_size, y_size };

      if (
        blacklist.length &&
        GridHelpers.isPositionInList(position, blacklist)
      ) {
        continue;
      }

      // Check for collisions against registered selections for this lever
      // and grip
      for (const selection of selections) {
        const selectionComponent = Utils.getById(
          components,
          selection.component,
        );
        const selectionSize = GridHelpers.getXYSizeByDirection(
          selectionComponent.x_size,
          selectionComponent.y_size,
          selection.direction,
        );
        const regComponent = {
          x: selection.x,
          y: selection.y,
          x_size: selectionSize.x_size,
          y_size: selectionSize.y_size,
        };

        if (isPositionColliding(position, regComponent)) {
          continue yLoop;
        }
      }

      id += 1;
      positions.push(position);
    }
  }

  return positions;
}

/**
 * Check if a component entity from editSelection has any available positions.
 *
 * @param {Object} componentEntity - Component data from editSelection.
 * @param {Array} positions - All available positions.
 * @param {Object} lever - Lever object.
 * @param {boolean} is_front - If on the front of the lever.
 * @return {boolean}
 */
export function componentHasPositions(
  componentEntity,
  positions,
  lever,
  is_front,
) {
  const filteredPos = positions.filter(
    (position) =>
      position.component === componentEntity.component &&
      position.lever === lever.pk &&
      position.is_front === is_front &&
      position.direction === componentEntity.direction,
  );

  return filteredPos.length !== 0 && filteredPos[0].entities.length !== 0;
}
