import {
  RECEIVE_CAN_SETTINGS,
  LOAD_CAN_SETTINGS,
  RECEIVE_CAN_SETTINGS_ERROR,
  CHANGE_CAN_SETTINGS,
  UPDATED_CAN_SETTINGS,
} from '../action-types';
import { assign, tuplesToOptions } from '../utils';

export const initialState = {
  values: {
    baud_rate: null,
    protocol: null,
  },
  options: {
    baud_rate: [],
    protocol: [],
  },
  isFetching: false,
  error: null,
};

export function canSettings(state = initialState, action) {
  switch (action.type) {
    // Loading CAN settings from endpoint
    case LOAD_CAN_SETTINGS:
      return assign(state, {
        isFetching: true,
      });

    // Receiving CAN settings from endpoint
    case RECEIVE_CAN_SETTINGS:
      return assign(state, {
        // Map to usable format here instead of on each render
        options: {
          baud_rate: tuplesToOptions(action.options.baud_rate),
          protocol: tuplesToOptions(action.options.protocol),
        },
        values: action.values,
        isFetching: false,
      });

    // Change CAN settings locally
    case CHANGE_CAN_SETTINGS:
      return assign(state, {
        values: assign(state.values, action.values),
      });

    // Receiving updated CAN settings from endpoint
    case UPDATED_CAN_SETTINGS:
      return assign(state, {
        values: action.values,
        isFetching: false,
      });

    // Error loading CAN settings from endpoint
    case RECEIVE_CAN_SETTINGS_ERROR:
      return assign(state, {
        error: action.error,
        isFetching: false,
      });

    default:
      return state;
  }
}
