import validator from 'validator';

import {
  LOAD_RFQ,
  RECEIVE_RFQ,
  RECEIVE_RFQ_ERROR,
  CHANGE_FIELD_VALUE,
  UNSET_FIELD_EDITING,
  VALIDATE_FIELD,
  VALIDATE_ALL_FIELDS,
  INIT_SUBMIT_FORM,
  AFTER_POST_FORM,
} from '../action-types';
import { DEBOUNCED } from '../middlewares/debounced';
import { format } from '../helpers/string';
import { initialState } from '../reducers/rfq';
import { ajaxCall, assign } from '../utils';

// Validators

const validators = {
  email: (value) => {
    if (!validator.isEmail(value)) {
      return global.gettext('You must enter a valid email address');
    }
    return '';
  },
};

function getFieldData(name) {
  return initialState.fields.filter((field) => field.name === name)[0];
}

export function getFieldError(name, value) {
  const fieldData = getFieldData(name);

  if (fieldData.isRequired && !value) {
    return format(global.gettext('%1 is required'), fieldData.label);
  }

  let errorText;
  if (validators[name]) {
    errorText = validators[name](value);

    if (errorText) {
      return errorText;
    }
  }

  return null;
}

function getAllFieldErrors() {
  const errors = [];

  global.store.getState().rfq.fields.forEach((field) => {
    const error = getFieldError(field.name, field.value);
    if (error) {
      errors.push({ name: field.name, error });
    }
  });

  return errors;
}

// Actions

export function loadRfq() {
  return {
    type: LOAD_RFQ,
  };
}

export function receiveRfq(json) {
  return {
    type: RECEIVE_RFQ,
    json,
  };
}

export function receiveRfqError(error) {
  return {
    type: RECEIVE_RFQ_ERROR,
    error,
  };
}

/**
 * Request RFQ fields.
 *
 * @return {Function} - Thunk
 */
export function requestRfq() {
  return (dispatch) => {
    // Set loading state
    dispatch(loadRfq());

    // Fetch RFQ fields
    return ajaxCall(
      `/api/${global.AppData.uid}/rfq/`,
      'GET',
      null,
      receiveRfq,
      receiveRfqError,
    );
  };
}

export function changeFieldValue(name, value) {
  return {
    type: CHANGE_FIELD_VALUE,
    name,
    value,
  };
}

export function validateField(name, value) {
  return {
    type: VALIDATE_FIELD,
    name,
    value,
    error: getFieldError(name, value),
  };
}

const validationTimeout = 1000;
let validationTimer = null;
export function delayedValidateField(name, value) {
  return (dispatch) => {
    if (global.AppData.environment === 'test') {
      dispatch(validateField(name, value));
    } else {
      clearTimeout(validationTimer);
      validationTimer = setTimeout(() => {
        dispatch(validateField(name, value));
      }, validationTimeout);
    }
  };
}

export function validateAllFields() {
  return {
    type: VALIDATE_ALL_FIELDS,
  };
}

function initSubmitForm() {
  return {
    type: INIT_SUBMIT_FORM,
  };
}

function afterPostForm(json) {
  return {
    type: AFTER_POST_FORM,
    json,
  };
}

function postFormError(error) {
  return {
    type: POST_FORM_ERROR,
    error,
  };
}

/**
 * Call the submit API endpoint to finish the form.
 *
 * @return {Object} Ajax promise.
 */
function postForm() {
  return ajaxCall(
    `/api/${global.AppData.uid}/rfq/submit`,
    'GET',
    null,
    afterPostForm,
    postFormError,
  );
}

/**
 * Submit the RFQ form.
 *
 * @return {Function} Thunk.
 */
export function submitForm() {
  return (dispatch) => {
    if (getAllFieldErrors().length) {
      dispatch(validateAllFields());
      return undefined;
    }

    dispatch(initSubmitForm());
    return postForm();
  };
}

/**
 * Save data for all fields to backend.
 *
 * @param {Object} fields - Field data where the key is the field name and the
 *   value is its value.
 * @return {Function} Thunk.
 */
function putForm(fields) {
  return () =>
    ajaxCall(
      `/api/${global.AppData.uid}/rfq/`,
      'PATCH',
      fields,
      receiveRfq,
      receiveRfqError,
    );
}

function dispatchUnsetEditing() {
  return {
    type: UNSET_FIELD_EDITING,
  };
}

/**
 * Unset the editing state and PUT all current valid field values.
 *
 * @return {Function} Thunk.
 */
function unsetEditing() {
  return (dispatch, getState) => {
    dispatch(dispatchUnsetEditing());

    // Don't save fields that are invalid to the server
    const validFields = getState().rfq.fields.filter(
      (field) => !field.error || field.value === '',
    );
    if (validFields.length) {
      const fieldData = validFields.reduce(
        (data, field) => assign(data, { [field.name]: field.value }),
        {},
      );
      dispatch(putForm(fieldData));
    }
  };
}

/**
 * Call `unsetEditing` after a delay.
 *
 * @return {Object} Action object.
 */
function debouncedUnsetEditing() {
  return {
    type: DEBOUNCED,
    meta: {
      debounce: {
        time: 750,
        callback: (dispatch) => {
          dispatch(unsetEditing());
        },
      },
    },
  };
}

/**
 * Save field data to store and backend.
 *
 * @param {string} name - Field name.
 * @param {string} value - Field value.
 * @param {boolean} isDelayed - If saving is delayed (debounced).
 * @return {Function} - Thunk
 */
export function saveField(name, value, isDelayed = true) {
  return (dispatch) => {
    dispatch(changeFieldValue(name, value));

    return isDelayed
      ? dispatch(debouncedUnsetEditing())
      : dispatch(unsetEditing());
  };
}
