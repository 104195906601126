import React from 'react';
import PropTypes from 'prop-types';

/**
 * Detect clicks outside this component.
 *
 * Props:
 *
 * - `children` (object) Required. Child nodes.
 * - `onClickOutside` (function) Required. Outside click callback.
 */
export default class ClickOutside extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onClickOutside: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
  }

  handleClick = (e) => {
    if (!this.refs.container.contains(e.target)) {
      // If `id` is set, it may be useful in event handlers
      this.props.onClickOutside(e, this.props.id);
    }
  };

  render() {
    const { children, onClickOutside, ...props } = this.props;
    return (
      <div {...props} ref="container">
        {children}
      </div>
    );
  }
}
