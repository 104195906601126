import validator from 'validator';

import {
  LOAD_PROJECT,
  RECEIVE_PROJECT_ERROR,
  SET_EMAIL,
  SET_EMAIL_ERROR,
  SET_COMPANY,
} from '../action-types';
import { ajaxCall } from '../utils';

import * as NotificationActions from './notifications';

const validators = {
  email: (email) => {
    if (!email) {
      return global.gettext('You must enter an email address.');
    }
    if (!validator.isEmail(email)) {
      return global.gettext('You must enter a valid email address.');
    }
    return '';
  },
};

function loadProject() {
  return {
    type: LOAD_PROJECT,
  };
}

export function setEmail(email) {
  return {
    type: SET_EMAIL,
    email,
  };
}

export function setEmailError(error) {
  return {
    type: SET_EMAIL_ERROR,
    error,
  };
}

export function setCompany(company) {
  return {
    type: SET_COMPANY,
    company,
  };
}

export function dispatchReceiveProjectError(error) {
  return {
    type: RECEIVE_PROJECT_ERROR,
    error,
  };
}

/**
 * Show a notification when there was an error creating the project.
 *
 * @param {string} error - Error message.
 * @return {Function} Thunk.
 */
function receiveProjectError(error) {
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: `${global.gettext(
          "The project couldn't be created",
        )}. ${error}`,
      }),
    );
    dispatch(dispatchReceiveProjectError(error));
  };
}

/**
 * Redirect to project main screen when receiving a response.
 *
 * @param {Object} json - The response data.
 * @return {Function} Thunk.
 */
function receiveProject(json) {
  return () => {
    window.location = `/${json.uid}/`;
  };
}

/**
 * Try requesting a new project.
 *
 * Will validate the email address and skip the project request if it's invalid.
 *
 * @param {string} email - Email address to send the secret link to.
 * @return {Function} Thunk.
 */
export function requestProject(email, company) {
  return (dispatch) => {
    const error = validators.email(email);
    if (error) {
      dispatch(setEmailError(error));
      return undefined;
    }

    dispatch(loadProject());

    return ajaxCall(
      '/api/projects/',
      'POST',
      { email, company },
      receiveProject,
      receiveProjectError,
      dispatch,
    );
  };
}
