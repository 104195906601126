import React from 'react';

/**
 * Warning message when appearing to be offline.
 *
 * @return {Object|undefined} React element.
 */
export default function OnlineStatus() {
  let component = <div />;

  if (!global.store.getState().comminfo.isOnline) {
    component = (
      <div className="online-status">
        {global.gettext('You appear to be offline. Changes may not be saved.')}
      </div>
    );
  }

  return component;
}
OnlineStatus.displayName = 'components/OnlineStatus';
