import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { characters } from '../helpers/string';
import * as LeverActions from '../actions/levers';
import * as RfqActions from '../actions/rfq';
import * as utils from '../utils';
import { Panel, Group } from '../components/PagePanel';
import Button from '../components/forms/Button';
import CommInfo from '../components/CommInfo';
import FieldError from '../components/forms/FieldError';
import Label from '../components/forms/Label';
import MessageBox from '../components/MessageBox';
import NumberInput from '../components/forms/NumberInput';
import Select from '../components/forms/Select';

import GlobalMessages from './GlobalMessages';
import Header from './Header';

/**
 * Base HTML class name for RFQ components.
 *
 * @type {string}
 */
const baseClass = 'rfq';

/**
 * RFQ form with inputs.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.fields - Form field objects.
 * @param {Function} props.onFormSubmit - Submit event handler.
 * @param {Function} props.onFieldChange - Field change event handler.
 * @param {Function} [props.onFieldBlur] - Field blur event handler.
 * @param {boolean} [props.isDisabled] - If the form fields should be disabled.
 * @return {Object} React element.
 */
export function Form(props) {
  const {
    fields,
    onFormSubmit,
    onFieldChange,
    onFieldBlur,
    isDisabled,
    children,
  } = props;

  let fieldElements = [];
  fields.forEach((field) => {
    let output;
    let error;

    const commonProps = {
      value: field.value,
      name: field.name,
      id: field.id,
      onChange: onFieldChange.bind(null, field.name),
      onBlur: onFieldBlur.bind(null, field.name),
      className: classnames(`${baseClass}-field`, {
        'input-error': field.error,
      }),
      disabled: isDisabled,
    };

    switch (field.type) {
      case 'select':
        output = <Select {...commonProps} options={field.options} />;
        break;

      case 'textarea':
        output = <textarea {...commonProps} />;
        break;

      case 'number':
        output = <NumberInput {...commonProps} allowZero allowEmpty />;
        break;

      default:
        output = <input type={field.type} {...commonProps} />;
    }

    if (field.error) {
      error = <FieldError text={field.error} />;
    }

    fieldElements.push(
      <div className={`${baseClass}-field-wrap`} key={field.name}>
        <Label text={field.label} />
        {output}
        {error}
      </div>,
    );
  });

  fieldElements = utils.chunkArray(
    fieldElements,
    Math.floor(fieldElements.length / 2),
  );

  return (
    <form
      className={`${baseClass}-form`}
      action=""
      onSubmit={onFormSubmit}
      noValidate
    >
      <div className={`${baseClass}-form-cols`}>
        <div className={`${baseClass}-form-col`}>{fieldElements[0]}</div>
        <div className={`${baseClass}-form-col`}>{fieldElements[1]}</div>
      </div>

      {children}
    </form>
  );
}
Form.displayName = 'containers/Rfq/Form';
Form.propTypes = {
  fields: PropTypes.array.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onFieldBlur: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
};
Form.defaultProps = {
  isDisabled: false,
  children: null,
};

/**
 * Main 'request for quotation' page component.
 */
export default class Rfq extends React.Component {
  static displayName = 'containers/Rfq';

  componentDidMount = () => {
    global.store.dispatch(RfqActions.requestRfq());
    global.store.dispatch(LeverActions.requestLevers());
  };

  /**
   * Submit the form.
   *
   * @param {Object} e - The submit event.
   */
  handleSubmit = (e) => {
    e.preventDefault();
    global.store.dispatch(RfqActions.submitForm());
  };

  /**
   * Set the field value on change and call a debounced validation.
   *
   * @param {string} fieldName - The field name.
   * @param {Object|string} e - The blur event or the field value.
   */
  handleFieldChange = (fieldName, e) => {
    // NumberInput passes the value when valid
    const value = e.target ? e.target.value : e;
    global.store.dispatch(RfqActions.delayedValidateField(fieldName, value));
    global.store.dispatch(RfqActions.saveField(fieldName, value));
  };

  /**
   * Validate the field value on blur.
   *
   * @param {string} fieldName - The field name.
   * @param {Object|string} e - The blur event or the field value.
   */
  handleFieldBlur = (fieldName, e) => {
    // NumberInput passes the value when valid
    const value = e.target ? e.target.value : e;
    global.store.dispatch(RfqActions.validateField(fieldName, value));
  };

  render() {
    const storeState = global.store.getState();
    const { rfq } = storeState;
    const isLoading = storeState.levers.isFetching || storeState.rfq.isFetching;
    const hasActiveLevers = utils.hasActiveLevers(storeState.levers.entities);
    // TODO: Do the same checks as when clicking the RFQ header link, since
    // users can manually to to the rfq URL to bypass.
    const isProjectInvalid = false;
    const buttonText = rfq.isSubmitting
      ? global.gettext('Sending') + characters.ellipsis
      : global.gettext('Send request');

    // TODO: Det här är en fix för att jag (inte Magnus) suger på Django och inte
    // kan få med valen för RFQ role i RfqActions.requestRfq()
    const fulFix = rfq.fields.find((f) => f.name === 'customer_role');
    fulFix.options = [
      { value: 'MO', label: 'Machine Operator' },
      { value: 'OT', label: 'Other' },
      { value: 'DE', label: 'Dealer' },
      { value: 'MN', label: 'Manufacturer' },
    ];
    return (
      <div className={baseClass}>
        <CommInfo />

        <Header
          loadingText={isLoading ? global.gettext('Loading RFQ') : null}
        />

        <GlobalMessages />

        {!isLoading && !hasActiveLevers && (
          <p className={`${baseClass}-info`}>
            {global.gettext('There are no active levers')}
          </p>
        )}

        <div className={`${baseClass}-main`}>
          {isLoading && <Panel className={`${baseClass}-placeholder`} />}

          {!isLoading && hasActiveLevers && (
            <Panel>
              <Group>
                <h1 className="h2">
                  {global.gettext('Request for quotation')}
                </h1>

                {rfq.submitted && (
                  <MessageBox
                    type="success"
                    title={global.gettext('Thank you!')}
                  >
                    {global.gettext(
                      'Your request has been sent successfully. We will contact you for the next step.',
                    )}
                  </MessageBox>
                )}

                {!rfq.submitted && (
                  <div className={`${baseClass}-help`}>
                    <p>
                      {global.gettext(
                        'Fill out the form below to send your request. When sent, the project will be locked for further changes and we will contact you for the next step.',
                      )}
                    </p>
                    <p>
                      <strong>
                        {global.gettext(
                          'All fields are required unless otherwise noted.',
                        )}
                      </strong>
                    </p>
                  </div>
                )}

                <Form
                  fields={rfq.fields}
                  isDisabled={rfq.submitted}
                  onFieldChange={this.handleFieldChange}
                  onFieldBlur={this.handleFieldBlur}
                  onFormSubmit={this.handleSubmit}
                >
                  <div className={`${baseClass}-submit`}>
                    <Button
                      type="submit"
                      isLoading={rfq.isSubmitting}
                      disabled={rfq.submitted || isProjectInvalid}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </Form>
              </Group>
            </Panel>
          )}
        </div>
      </div>
    );
  }
}
