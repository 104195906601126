import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DocumentLink from './DocumentLink';

/**
 * Base class for `ItemList` components.
 *
 * @type {string}
 */
const BASE_CLASS = 'item-list';

/**
 * The main text in an item `List`.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function ItemText(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-text`, className);

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
ItemText.displayName = 'components/ItemList/ItemText';
ItemText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
ItemText.defaultProps = {
  children: null,
  className: '',
};

/**
 * A slightly prominent link in an item `List`.
 *
 * @param {Object} props - Component props.
 * @param {string} props.href - Link URL.
 * @param {boolean} [props.isDocument] - If the link should have a document
 *   icon. Defaults to true.
 * @return {Object} React element.
 */
export function ItemLink(props) {
  const { href, isDocument, children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-link`, className);

  let link;
  if (isDocument) {
    link = <DocumentLink url={href} text={children} />;
  } else {
    link = (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }

  return (
    <p {...passProps} className={cls}>
      {link}
    </p>
  );
}
ItemLink.displayName = 'components/ItemList/ItemLink';
ItemLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  isDocument: PropTypes.bool,
};
ItemLink.defaultProps = {
  children: null,
  className: '',
  isDocument: true,
};

/**
 * A title in an item `List`.
 *
 * @param {Object} props - Component props.
 * @param {number} props.level - Heading level, 1-6.
 * @return {Object} React element.
 */
export function ItemTitle(props) {
  const { level, children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-title h-plain`, className);
  const Tag = `h${level}`;

  return (
    <Tag {...passProps} className={cls}>
      {children}
    </Tag>
  );
}
ItemTitle.displayName = 'components/ItemList/ItemTitle';
ItemTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
};
ItemTitle.defaultProps = {
  children: null,
  className: '',
  level: 3,
};

/**
 * The main body of text in a list `Item`.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function ItemBody(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-body`, className);

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
ItemBody.displayName = 'components/ItemList/ItemBody';
ItemBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
ItemBody.defaultProps = {
  children: null,
  className: '',
};

/**
 * An image in an item `List`.
 *
 * Should be placed outside an eventual `ItemBody` and should probably be the
 * first child.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function ItemImage(props) {
  const { src, alt, children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-image`, className);

  return (
    <div {...passProps} className={cls}>
      <img src={src} alt={alt || ''} />
      {children}
    </div>
  );
}
ItemImage.displayName = 'components/ItemList/ItemImage';
ItemImage.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
};
ItemImage.defaultProps = {
  alt: '',
  children: null,
  className: '',
};

/**
 * A single item in an item `List`.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function Item(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-item`, className);

  return (
    <li {...passProps} className={cls}>
      {children}
    </li>
  );
}
Item.displayName = 'components/ItemList/Item';
Item.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Item.defaultProps = {
  className: '',
};

/**
 * A generic list of items.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.isInverted - Use inverted styling.
 * @return {Object} React element.
 */
export function List(props) {
  const { isInverted, children, className, ...passProps } = props;
  const cls = classnames(BASE_CLASS, className, {
    [`${BASE_CLASS}-inverted`]: isInverted,
  });

  return (
    <ul {...passProps} className={cls}>
      {children}
    </ul>
  );
}
List.displayName = 'components/ItemList/List';
List.propTypes = {
  children: PropTypes.node.isRequired,
  isInverted: PropTypes.bool,
  className: PropTypes.string,
};
List.defaultProps = {
  isInverted: false,
  className: '',
};

/**
 * A wrap for an item `List`.
 *
 * @param {Object} props - Component props.
 * @return {Object} React element.
 */
export function ListWrap(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames(`${BASE_CLASS}-wrap`, className);

  return (
    <div {...passProps} className={cls}>
      {children}
    </div>
  );
}
ListWrap.displayName = 'components/ItemList/ListWrap';
ListWrap.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
ListWrap.defaultProps = {
  className: '',
};
