import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import StaticImage from './StaticImage';

/**
 * A grip addon that can be toggled as active or inactive.
 */
export default class GripAddon extends React.Component {
  static displayName = 'components/GripAddon';

  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    name: PropTypes.oneOf(['base', 'hand-rest']).isRequired,
    onToggle: PropTypes.func,
    isInteractive: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onToggle: null,
  };

  /**
   * Trigger handler prop with new active state.
   *
   * @param {Object} e - Click event.
   */
  handleClick = (e) => {
    if (this.props.isInteractive) {
      e.preventDefault();
      this.props.onToggle(!this.props.isActive);
    }
  };

  render() {
    // This is only an addition to the existing checkboxes, we specifically
    // only want to make this available to sighted users with a pointer.
    /* eslint-disable
    jsx-a11y/click-events-have-key-events,
    jsx-a11y/no-static-element-interactions
    */

    const { isActive, name } = this.props;
    const className = classnames(`grip-addon grip-addon--${name}`, {
      'grip-addon--active': isActive,
      'grip-addon--inactive': !isActive,
    });

    return (
      <div className={className}>
        {Boolean(this.props.onToggle) && (
          <div className="grip-addon-click-area" onClick={this.handleClick} />
        )}
        <div className="grip-addon-image">
          <StaticImage name={`${name}.png`} />
        </div>
      </div>
    );
  }
}
