import {
  REGISTER_DRAGOVER,
  REGISTER_DRAGLEAVE,
  REGISTER_DRAGSTART,
  REGISTER_MOVESTART,
  REGISTER_DRAGEND,
  REGISTER_DROP,
  LOAD_LEVER,
  RESET_EDIT_SELECTIONS,
} from '../action-types';
import * as StringHelpers from '../helpers/string';
import * as constants from '../constants';

export function registerDragStart(id, direction) {
  return {
    type: REGISTER_DRAGSTART,
    id,
    direction,
  };
}

export function registerMoveStart(id, direction) {
  return {
    type: REGISTER_MOVESTART,
    id,
    direction,
  };
}

export function registerDragEnd() {
  return {
    type: REGISTER_DRAGEND,
  };
}

export function registerDragOver(id) {
  return {
    type: REGISTER_DRAGOVER,
    id,
  };
}

export function registerDragLeave() {
  return {
    type: REGISTER_DRAGLEAVE,
  };
}

export function registerDrop() {
  return {
    type: REGISTER_DROP,
  };
}

function loadLever(components, leverId, is_front) {
  return {
    type: LOAD_LEVER,
    components,
    leverId,
    is_front,
  };
}

/**
 * Create editable component entities
 *
 * @param {number} leverId - Lever ID/pk.
 * @param {boolean} is_front - If the lever is a front version.
 * @return {Function} Thunk
 */
export function editLever(leverId, is_front) {
  return (dispatch, getState) => {
    const { components } = getState();
    const loadedComponents = [];

    components.entities.forEach((component) => {
      if (component.has_direction) {
        // Create entities per direction. Vertical is default.
        loadedComponents.push({
          component: component.pk,
          name: StringHelpers.format(
            global.gettext('%1 - vertical'),
            component.name,
          ),
          direction: constants.VERTICAL,
        });
        loadedComponents.push({
          component: component.pk,
          name: StringHelpers.format(
            global.gettext('%1 - horizontal'),
            component.name,
          ),
          direction: constants.HORIZONTAL,
        });
      } else {
        loadedComponents.push({
          component: component.pk,
          name: component.name,
          direction: constants.NOT_APPLICABLE,
        });
      }
    });

    dispatch(loadLever(loadedComponents, leverId, is_front));
  };
}

export function resetEditSelections() {
  return {
    type: RESET_EDIT_SELECTIONS,
  };
}
