import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * A visible label for a `FieldGroup`.
 *
 * @param {Object} props - Component props.
 */
export default function FieldGroupLabel(props) {
  const { children, className, ...passProps } = props;
  const cls = classnames('field-group-label', className);

  return (
    <p {...passProps} className={cls}>
      {children}
    </p>
  );
}
FieldGroupLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
FieldGroupLabel.defaultProps = {
  className: '',
};
