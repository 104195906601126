import React from 'react';
import PropTypes from 'prop-types';

import * as notificationActions from '../actions/notifications';
import Button from '../components/forms/Button';
import Icon from '../components/Icon';

/**
 * A single notification.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.notification - The notification object.
 * @param {Function} props.onDismissClick - Close button click handler.
 * @return {Object} React element.
 */
export function Notification(props) {
  const { notification, onDismissClick } = props;
  const { type, message, isDismissible } = notification;

  return (
    <div className={`notification notification-${type}`}>
      <span className="notification-text">{message}</span>
      {Boolean(isDismissible && onDismissClick) && (
        <Button
          className="notification-close"
          onClick={onDismissClick}
          aria-label={global.gettext('Close')}
        >
          <Icon name="cross" />
        </Button>
      )}
    </div>
  );
}
Notification.displayName = 'containers/Notifications/Notification';
Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  onDismissClick: PropTypes.func,
};
Notification.defaultProps = {
  onDismissClick: null,
};

/**
 * Main notification component, listing all notifications.
 *
 * @return {Object} React element.
 */
export default class Notifications extends React.Component {
  static displayName = 'containers/Notifications';

  /**
   * Remove a notification when the close button is clicked.
   *
   * @param {string} notificationId - The notification ID.
   * @param {Object} e - The click event.
   */
  handleDismissClick = (notificationId, e) => {
    e.preventDefault();
    global.store.dispatch(
      notificationActions.removeNotification(notificationId),
    );
  };

  render() {
    return (
      <div className="notifications">
        {global.store.getState().notifications.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            onDismissClick={
              notification.isDismissible
                ? this.handleDismissClick.bind(this, notification.id)
                : null
            }
          />
        ))}
      </div>
    );
  }
}
