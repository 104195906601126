export const timers = {};

export const DEBOUNCED = 'DEBOUNCED';
/**
 * Debounced action middleware. Runs actions after a timeout.
 *
 * Expects the action `type` to be the DEBOUNCED constant and actions as either
 * a `callback` function or an `action` object under the `meta.debounced` object.
 *
 * - The `callback` function should be a thunk, i.e. it will receive `dispatch`
 *   and `getState` as parameters.
 * - The `action` object should be a regular redux action object.
 */
export const debouncedMiddleware = () => (dispatch) => (action) => {
  const { meta: { debounce = {} } = {}, type } = action;

  const { time, key = type } = debounce;

  if (!time || !key) {
    return dispatch(action);
  }

  const debouncedAction = debounce.action;
  const debouncedCallback = debounce.callback;

  if (timers[key]) {
    clearTimeout(timers[key]);
  }

  timers[key] = setTimeout(() => {
    dispatch(debouncedCallback || debouncedAction);
  }, time);
};
