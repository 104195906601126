import React from 'react';

import * as utils from '../utils';

/**
 * Main logo as an SVG image.
 */
export default function Logo() {
  return (
    <img
      className="logo"
      src={utils.staticUrl('img/logo.svg')}
      alt="SVAB grip configurator"
    />
  );
}
