import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * An overlay.
 *
 * @param {Object} props - Component props.
 * @param {string} [props.name] - An name to prefix additional class names for
 *   both the outer and inner element.
 */
export default function Overlay(props) {
  const { name, className, children } = props;
  const outerClass = classnames('overlay', className, {
    [`${name}-overlay`]: name,
  });
  const innerClass = classnames('overlay-content', {
    [`${name}-overlay-content`]: name,
  });

  return (
    <div className={outerClass}>
      <div className={innerClass}>{children}</div>
    </div>
  );
}
Overlay.displayName = 'components/Overlay';
Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};
Overlay.defaultProps = {
  className: '',
  name: '',
};
