import { combineReducers } from 'redux';

import { accessories } from './accessories';
import { canSettings } from './canSettings';
import { comminfo } from './comminfo';
import { components } from './component';
import { editSelections } from './editSelections';
import { levers } from './levers';
import { notifications } from './notifications';
import { positions } from './positions';
import { project } from './project';
import { rfq } from './rfq';
import { selections } from './selections';
import { signup } from './signup';

// eslint-disable-next-line import/no-mutable-exports
let rootReducer;

const globalReducers = {
  notifications,
  comminfo,
};

// Create a root reducer per app section
switch (global.AppData.section) {
  case 'signup':
    rootReducer = combineReducers({
      ...globalReducers,
      signup,
    });
    break;

  case 'app':
    rootReducer = combineReducers({
      ...globalReducers,
      accessories,
      canSettings,
      components,
      editSelections,
      levers,
      positions,
      project,
      rfq,
      selections,
    });
    break;

  case 'overview':
    rootReducer = combineReducers({
      ...globalReducers,
      accessories,
      canSettings,
      components,
      editSelections,
      levers,
      positions,
      project,
      selections,
    });
    break;

  case 'rfq':
    rootReducer = combineReducers({
      ...globalReducers,
      canSettings,
      components,
      levers,
      rfq,
      selections,
    });
    break;

  case 'render_selection':
    rootReducer = combineReducers({
      ...globalReducers,
      canSettings,
      components,
      levers,
      selections,
    });
    break;

  default:
  // No default
}

export default rootReducer;
