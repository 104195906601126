import React from 'react';
import PropTypes from 'prop-types';

import AccessoryList from '../components/AccessoryList';
import ToggleDialog from '../components/ToggleDialog';
import * as LeverActions from '../actions/levers';
import { isProjectLocked } from '../utils';
import Icon from '../components/Icon';

/**
 * Choose accessories through a toggle dialog and list already chosen ones.
 *
 * @param {Object} lever - Left or right lever object.
 */
export default class Accessories extends React.PureComponent {
  static displayName = 'containers/Accessories';

  static propTypes = {
    lever: PropTypes.object.isRequired,
  };

  handleAdd = (accessoryId) => {
    global.store.dispatch(
      LeverActions.putAccessory(this.props.lever.pk, accessoryId),
    );
    this.refs.toggle.close();
  };

  handleRemove = (accessoryId) => {
    global.store.dispatch(
      LeverActions.deleteAccessory(this.props.lever.pk, accessoryId),
    );
  };

  render() {
    const accessories = global.store.getState().accessories.entities;

    // Get accessories whose IDs are saved to the lever
    const chosenAccessories = accessories.filter(
      (accessory) => this.props.lever.accessories.indexOf(accessory.pk) !== -1,
    );

    // Get accessories whose IDs are not included in the chosen accessories
    const availableAccessories = accessories.filter(
      (accessory) => chosenAccessories.indexOf(accessory) === -1,
    );
    const toggleButtonText = [
      <Icon key={2} name="plus" />,
      <span key={1} className="text">
        {global.gettext('Add accessory')}
      </span>,
    ];

    return (
      <div className="accessory-selection">
        <AccessoryList items={chosenAccessories} onRemove={this.handleRemove} />

        <ToggleDialog
          buttonText={toggleButtonText}
          disabled={!availableAccessories.length || isProjectLocked()}
          isInline
          ref="toggle"
        >
          <AccessoryList items={availableAccessories} onAdd={this.handleAdd} />
        </ToggleDialog>
      </div>
    );
  }
}
