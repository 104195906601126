/**
 * Various debugging and environment helpers.
 */

/**
 * Check if running tests.
 *
 * @type {boolean}
 */
export const IS_TEST = process.env.NODE_ENV === 'test';

/**
 * If currently running in a development environment.
 *
 * @type {boolean}
 */
export const IS_DEV = process.env.NODE_ENV === 'development';

/**
 * Call a console method if available.
 *
 * @param {string} method - The method name (log, warn...).
 * @param {...mixed} values - The arguments for the method.
 */
export function consoleCall(method: string, ...values: Array<mixed>): void {
  if (
    // Prevent clutter during tests
    !IS_TEST &&
    // Using `window` instead of `global` here since the typeof check doesn't
    // get transpiled and stays as `global`, thus being undefined.
    typeof window !== 'undefined' &&
    window.console &&
    typeof window.console[method] === 'function'
  ) {
    window.console[method](...values);
  }
}

/**
 * Log a message to the console, if available.
 *
 * @param {...mixed} values - The values to log.
 */
export function consoleLog(...values: Array<mixed>): void {
  consoleCall('log', ...values);
}

/**
 * Log an info message to the console, if available.
 *
 * @param {...mixed} values - The values to log.
 */
export function consoleInfo(...values: Array<mixed>): void {
  consoleCall('info', ...values);
}

/**
 * Log a warning message to the console, if available.
 *
 * @param {...mixed} values - The values to send.
 */
export function consoleWarn(...values: Array<mixed>): void {
  consoleCall('warn', ...values);
}

/**
 * Log an error message to the console, if available.
 *
 * @param {...mixed} values - The values to send.
 */
export function consoleError(...values: Array<mixed>): void {
  consoleCall('error', ...values);
}

/**
 * Log a value to the console when debugging.
 *
 * @param {...mixed} values - The values to log.
 */
export function debug(...values: Array<mixed>): void {
  if (IS_DEV) {
    consoleLog(...values);
  }
}
