import React from 'react';
import PropTypes from 'prop-types';

/**
 * A select field.
 *
 * @param {Object} props - Component props.
 * @param {Array.<Object>} props.options - Option data.
 * @param {string} props.value - The selected value.
 */
export default function Select(props) {
  const { disabled, onChange, options, value, ...passProps } = props;

  return (
    <select {...passProps} onChange={onChange} value={value}>
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={disabled || option.disabled}
        >
          {option.label}
        </option>
      ))}
    </select>
  );
}
Select.displayName = 'components/forms/Select';
Select.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
};
Select.defaultProps = {
  disabled: false,
};
