import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * An inline error message for form fields.
 *
 * @param {Object} props - Component props.
 * @param {string} props.text - The error message to display.
 * @return {Function} React element.
 */
export default function FieldError(props) {
  const { text, className, ...passProps } = props;
  const cls = classnames('field-error', className);

  return (
    <span {...passProps} className={cls}>
      {text}
    </span>
  );
}
FieldError.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
FieldError.defaultProps = {
  className: '',
};
