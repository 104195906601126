import * as NotificationActions from './actions/notifications';
import * as CommInfoActions from './actions/comminfo';
import * as constants from './constants';
import { getCookie } from './helpers/cookie';
import { IS_DEV, consoleError } from './helpers/debug';

/**
 * Get all objects from list by attribute
 *
 * @param {Array} list - The list
 * @param {string} attr - The attribute
 * @param {number|string} value - The value to look for
 * @return {Array} The objects
 */
export function getAllByAttribute(list, attr, value) {
  return list.filter((obj) => obj[attr] === value);
}

/**
 * Get object from list by attribute
 *
 * @param {Array} list - The list
 * @param {string} attr - The attribute
 * @param {number|string} value - The value to look for
 * @return {Object|Undefined} The object
 */
export function getByAttribute(list, attr, value) {
  return getAllByAttribute(list, attr, value)[0];
}

/**
 * Get object from list by id/pk
 *
 * @param {Array} list - The list
 * @param {number} id - The id/pk
 * @return {Object|Undefined} The object
 */
export function getById(list, id) {
  return getByAttribute(list, 'pk', id);
}

let globalCount = 0;
/**
 * Get a globally unique number.
 *
 * @return {number}
 */
export function getGlobalCount() {
  globalCount += 1;
  return globalCount;
}

/**
 * Check if the project is locked (i.e. if the RFQ is submitted);
 *
 * @return {boolean}
 */
export function isProjectLocked() {
  const { rfq } = global.store.getState();

  return rfq && rfq.submitted;
}

// Ajax

/**
 * Check status
 *
 * @param {Object} response - The response
 * @return {Object|Error}
 */
function checkStatus(response) {
  if (response.ok) {
    return response;
  }
  if (response.status >= 500 && response.status < 600) {
    // Show notification when we have a server error
    global.store.dispatch(
      NotificationActions.addNotification({
        message: response.statusText,
      }),
    );
  }
  // Throw error
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Parse json response
 *
 * @param {Object} response - The response
 * @return {Object|Null}
 */
function parseJSON(response) {
  if (response.status === 204) {
    return null;
  }
  return response.json();
}

/**
 * Ajax call helper.
 * A wrapper for `fetch` with default headers and promises handling
 * Creates a standardized `fetch` object that checks status,
 * parses json response and returns promises to callbacks.
 * It adds request to comminfo and removes or clears it afterwards.
 *
 * @param {string} url - Url to send ajax call to
 * @param {string} httpMethod - The call method
 * @param {Object} body - Body data
 * @param {Function} okCallback - Callback when response is ok
 * @param {Function} errorCallback - Callback when response has error
 * @param {Object} callbackData - Extra data that ships with callbacks as
 *   second parameter
 * @return {Promise} A `fetch` instance
 */
export function ajaxCall(
  url,
  httpMethod,
  body,
  okCallback,
  errorCallback,
  callbackData = null,
) {
  // Ensure method always is uppercase
  const method = httpMethod.toUpperCase();

  // Add request to comminfo
  if (method !== 'GET') {
    global.store.dispatch(CommInfoActions.addRequest());
  }

  // Create options object with method and default headers
  const options = {
    method,
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json,*/*;q=0.1',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
      credentials: 'same-origin',
      'X-REQUESTED-WITH': 'XMLHttpRequest',
      'X-CSRFTOKEN': getCookie('csrftoken'),
    }),
  };
  // Serialize body as json
  if (body) {
    options.body = JSON.stringify(body);
  }
  const request = new Request(global.AppData.endpoint + url);

  return fetch(request, options)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => {
      if (method !== 'GET') {
        // Remove request from comminfo
        global.store.dispatch(CommInfoActions.removeRequest());
      }
      if (okCallback) {
        // Run ok callback
        global.store.dispatch(okCallback(data, callbackData));
      }
    })
    .catch((error) => {
      consoleError('ajaxCall catch:', error);
      if (method !== 'GET') {
        // Clear requests from comminfo
        global.store.dispatch(CommInfoActions.clearRequests());
      }
      // Run error callback
      global.store.dispatch(errorCallback(error, callbackData));
      // throw error;
    });
}

// A small wrapper for Object.assign
export function assign(...newState) {
  return Object.assign({}, ...newState);
}

/**
 * Generates a UUID.
 *
 * @return {string}
 */
export function generateUUID() {
  /* eslint-disable no-bitwise */
  let d = new Date().getTime();

  const replacer = (match) => {
    const rnd = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (match === 'x' ? rnd : (rnd & 0x3) | 0x8).toString(16);
  };

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, replacer);
}

/**
 * Check if any of the levers are active.
 *
 * @param {Array} levers - Lever entity objects.
 * @return {boolean}
 */
export function hasActiveLevers(levers) {
  return levers.filter((lever) => lever.is_active).length > 0;
}

/**
 * Get levers ordered by left to right
 *
 * @param {Array} levers - Lever entity objects.
 * @return {Array} Ordered lever entity objects.
 */
export function getOrderedLevers(levers) {
  if (!levers.length) {
    return [];
  }
  return [
    getByAttribute(levers, 'grip', constants.LEFT),
    getByAttribute(levers, 'grip', constants.RIGHT),
  ];
}

/**
 * Check if a grip has a specific side.
 *
 * @private
 * @param {string|Object} grip - Grip string, grip constant or lever object.
 * @param {string} side - Side constant.
 * @return {boolean}
 */
function isGripSide(grip, side) {
  // Probably passed a lever object, which'll have lever.grip
  if (typeof grip === 'object' && grip.grip) {
    grip = grip.grip;
  }

  return grip === side || grip === constants.GRIP_STRING[side];
}

/**
 * Check if a grip is left side.
 *
 * @param {string|Object} grip - Grip string, grip constant or lever object.
 * @return {boolean}
 */
export function isLeftGrip(grip) {
  return isGripSide(grip, constants.LEFT);
}

/**
 * Check if a grip is right side.
 *
 * @param {string|Object} grip - Grip string, grip constant or lever object.
 * @return {boolean}
 */
export function isRightGrip(grip) {
  return isGripSide(grip, constants.RIGHT);
}

/**
 * Get the label for a CAN setting.
 *
 * @param {Array.<Array>} options - All available options for a setting.
 * @param {string} selectedValue - The selected setting value.
 * @return {string} Empty if the selected value doesn't match an option.
 * @example
 *
 * getCANSettingLabel(options.protocol, values.protocol)
 * // => 'CANopen'
 */
export function getCANSettingLabel(options, selectedValue) {
  return (options.find((opt) => opt.value === selectedValue) || {}).label || '';
}

/**
 * Convert an array of tuples (likely from an API response) to option objects.
 *
 * @param {Array.<Array>} tuples - Array of tuple arrays.
 * @example
 *
 * const data = [['on', 'Yes'], ['off', 'No']]
 * tuplesToOptions(data)
 * // => [{ value: 'on', label: 'Yes' }, { value: 'off', label: 'No' }]
 */
export function tuplesToOptions(tuples) {
  return tuples.map((opt) => ({ value: opt[0], label: opt[1] }));
}

/**
 * Split an array into chunks.
 *
 * @param {Array} arr - The array to split.
 * @param {number} chunkSize - Number of items in each chunk. Defaults to half
 *    the array size if not specified.
 * @return {Array}
 */
export function chunkArray(arr, chunkSize) {
  const chunks = [];
  let i = 0;
  const len = arr.length;

  if (len < 2) {
    return arr;
  }

  const size = chunkSize || Math.ceil(len / 2);

  while (i < len) {
    chunks.push(arr.slice(i, (i += size)));
  }

  return chunks;
}

/**
 * Return a full static url path.
 *
 * @param {string} path - A relative static path
 * @return {string}
 */
export function staticUrl(path) {
  if (IS_DEV) {
    return `/static/${path}`;
  }
  return global.django.static(path);
}

export function addSideSpecific(defaultKey, grip) {
  const side = grip === constants.LEFT ? 'left' : 'right';
  return `${defaultKey}_${side}`;
}
