import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import * as ComponentHelpers from '../helpers/component';
import * as StringHelpers from '../helpers/string';
import * as constants from '../constants';
import * as Utils from '../utils';

import {
  ListWrap,
  List,
  Item,
  ItemImage,
  ItemBody,
  ItemTitle,
  ItemLink,
  ItemText,
} from './ItemList';

/**
 * Component list item.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.componentEntity - The component entity for the
 *   current lever side.
 * @param {Object} props.componentData - Component 'core' data.
 * @param {Object} props.dragging - The currently dragged component, if any.
 * @param {boolean} props.isDraggable - If this component can be dragged.
 * @param {Object} props.lever - Lever object.
 * @param {Function} props.onDragStart - HTML5 dragstart event handler.
 * @param {Function} props.onDragEnd - HTML5 dragend event handler.
 * @return {Object} React element.
 */
export function Component(props) {
  const {
    componentEntity,
    componentData,
    dragging,
    lever,
    isDraggable,
    onDragStart,
    onDragEnd,
  } = props;

  const baseClass = 'component-item';
  const className = classnames(
    `${baseClass} ${baseClass}-${constants.GRIP_STRING[lever.grip]}`,
    {
      'is-dragging':
        componentEntity.component === dragging.id &&
        componentEntity.direction === dragging.direction,
      'is-disabled': !isDraggable,
    },
  );

  let info;
  if (componentData.info) {
    info = (
      <ItemText>
        {StringHelpers.lineBreaksToParagraphs(componentData.info)}
      </ItemText>
    );
  }

  let datasheet;
  if (componentData.datasheet) {
    datasheet = <ItemLink href={componentData.datasheet} />;
  }

  let imageKey = constants.DIRECTION_IMAGE[componentEntity.direction];
  if (componentData.has_left_right) {
    imageKey = Utils.addSideSpecific(imageKey, lever.grip);
  }

  return (
    <Item
      onDragStart={onDragStart.bind(null, isDraggable)}
      onDragEnd={onDragEnd}
      draggable={isDraggable ? 'true' : 'false'}
      className={className}
    >
      <ItemImage
        className={`${baseClass}-image`}
        src={componentData.image[imageKey]}
      />
      <ItemBody>
        <ItemTitle>{componentEntity.name}</ItemTitle>
        {datasheet}
        {info}
      </ItemBody>
    </Item>
  );
}
Component.displayName = 'components/ComponentList/Component';
Component.propTypes = {
  componentEntity: PropTypes.object.isRequired,
  componentData: PropTypes.object.isRequired,
  dragging: PropTypes.object.isRequired,
  isDraggable: PropTypes.bool.isRequired,
  lever: PropTypes.object.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

/**
 * Component list.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lever - Lever object.
 * @param {Object} props.editSelections - Store data
 * @param {Object} props.components - 'Core' component entities/data.
 * @param {Array} props.positions - All available position entities.
 * @param {Function} props.onDragStart - HTML5 dragstart event handler.
 * @param {Function} props.onDragEnd - HTML5 dragend event handler.
 * @return {Object} React element.
 */
export default function ComponentList(props) {
  const {
    lever,
    editSelections,
    components,
    positions,
    onDragStart,
    onDragEnd,
    isDraggable,
  } = props;

  return (
    <ListWrap className="component-list">
      <h2>{global.gettext('Components')}</h2>
      <p>{global.gettext('Drag and drop components onto the grip')}</p>
      <List>
        {editSelections.entities.map((componentEntity, i) => {
          const isComponentDraggable =
            isDraggable &&
            ComponentHelpers.componentHasPositions(
              componentEntity,
              positions,
              lever,
              editSelections.is_front,
            );
          const componentData = Utils.getById(
            components.entities,
            componentEntity.component,
          );

          return (
            <Component
              key={i}
              componentEntity={componentEntity}
              componentData={componentData}
              dragging={editSelections.dragging}
              isDraggable={isComponentDraggable}
              lever={lever}
              onDragStart={onDragStart.bind(
                null,
                componentEntity,
                componentData,
              )}
              onDragEnd={onDragEnd}
            />
          );
        })}
      </List>
    </ListWrap>
  );
}
ComponentList.displayName = 'components/ComponentList';
ComponentList.propTypes = {
  editSelections: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  lever: PropTypes.object.isRequired,
  positions: PropTypes.array.isRequired,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  isDraggable: PropTypes.bool,
};
ComponentList.defaultProps = {
  isDraggable: true,
};
