/**
 * Functions for browser cookies.
 *
 * Based on https://github.com/madmurphy/cookies.js
 */

import { buildString } from './string';

const RESERVED_COOKIE_NAME_REGEX = /^(?:expires|max-age|path|domain|secure)$/i;

/**
 * Format/encode a cookie key.
 *
 * @ignore
 * @param {string} key - Cookie name.
 * @return {string}
 */
function formatCookieKey(key) {
  return encodeURIComponent(key).replace(/[-.+*]/g, '\\$&');
}

/**
 * Get a cookie value.
 *
 * @param {string} key - Cookie name.
 * @return {?string}
 */
export function getCookie(key) {
  if (!key) {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${formatCookieKey(key)}\\s*\\=\\s*([^;]*).*$)|^.*$`,
        ),
        '$1',
      ),
    ) || null
  );
}

/**
 * Set a cookie value.
 *
 * @param {string} key - Cookie name.
 * @param {*} value - Cookie value, will be encoded with encodeURIComponent
 *   and therefore stringified.
 * @param {number|string|Date} [end] - Expire date. Defaults to one year from
 *   the current time.
 * @param {string} [path] - Absolute path where the cookie is available.
 *   Defaults to '/', i.e. everywhere.
 * @param {string} [domain] - Domain where the cookie is available. Defaults
 *   to the current host.
 * @param {boolean} [secure] - Only send the cookie over https. Defaults to
 *   false.
 * @return {boolean} True if cookie has been set, false otherwise.
 */
export function setCookie(
  key,
  value,
  end = 31536000,
  path = '/',
  domain = null,
  secure = false,
) {
  if (!key || RESERVED_COOKIE_NAME_REGEX.test(key)) {
    return false;
  }
  let expires;
  if (end) {
    switch (end.constructor) {
      case Number:
        expires =
          end === Infinity
            ? '; expires=Fri, 1 Jan 2038 12:00:00 GMT'
            : `; max-age=${end}`;
        break;
      case String:
        expires = `; expires=${end}`;
        break;
      case Date:
        expires = `; expires=${end.toUTCString()}`;
        break;
      default:
        expires = '';
    }
  }
  document.cookie = buildString([
    `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    expires,
    domain && `; domain=${domain}`,
    path && `; path=${path}`,
    secure && '; secure',
  ]);
  return true;
}

/**
 * Check if a cookie exists.
 *
 * @param {string} key - Cookie name.
 * @return {boolean}
 */
export function hasCookie(key) {
  if (!key || RESERVED_COOKIE_NAME_REGEX.test(key)) {
    return false;
  }
  return new RegExp(`(?:^|;\\s*)${formatCookieKey(key)}\\s*\\=`).test(
    document.cookie,
  );
}

/**
 * Remove a cookie if it exists.
 *
 * @param {string} key - Cookie name.
 * @param {string} path - Cookie path, must match if specified.
 * @param {string} domain - Cookie domain, must match if specified.
 * @return {boolean} True if the cookie was removed, false if it doesn't exist.
 */
export function removeCookie(key, path, domain) {
  if (!hasCookie(key)) {
    return false;
  }
  document.cookie = buildString([
    `${encodeURIComponent(key)}=}`,
    '; expires=Thu, 01 Jan 1970 00:00:00 GMT',
    domain && `; domain=${domain}`,
    path && `; path=${path}`,
  ]);

  return true;
}
