import validator from 'validator';

import {
  RECEIVE_SELECTIONS,
  LOAD_SELECTIONS,
  RECEIVE_SELECTIONS_ERROR,
  ADD_SELECTION,
  FETCH_SELECTION,
  RECEIVE_SELECTION,
  RECEIVE_SELECTION_ERROR,
  REMOVE_SELECTION,
  CHANGE_SELECTION_SETTINGS,
  RESET_LEVER_SELECTIONS,
  UNSET_SELECTION_EDITING,
} from '../action-types';
import * as Utils from '../utils';

export const initialEntityState = {
  pk: null,
  component: null,
  direction: 'HL',
  is_front: true,
  isEditing: false,
  isFetching: false,
  settings: [],
  lever: null,
  x: null,
  y: null,
};

function entity(state, action) {
  switch (action.type) {
    // Set fetching state
    case FETCH_SELECTION:
      if (state.pk !== action.id) {
        return state;
      }

      return Utils.assign(state, {
        isFetching: true,
      });

    // Unset editing state
    case UNSET_SELECTION_EDITING:
      if (state.pk !== action.id) {
        return state;
      }

      return Utils.assign(state, {
        isEditing: false,
      });

    // Receive selection from backend
    case RECEIVE_SELECTION:
      if (
        state.pk !== action.selection.pk &&
        !validator.isUUID(state.pk.toString())
      ) {
        return state;
      }

      // Don't overwrite state if currently editing
      if (state.isEditing) {
        return state;
      }

      return Utils.assign(state, action.selection, {
        isFetching: false,
      });

    // Change selection settings
    case CHANGE_SELECTION_SETTINGS:
      if (state.pk !== action.id) {
        return state;
      }

      return Utils.assign(state, {
        isEditing: true,
        settings: state.settings.map((setting) => {
          if (setting.name === action.name) {
            return Utils.assign(setting, {
              value: action.value,
            });
          }
          return setting;
        }),
      });

    default:
      return state;
  }
}

export const initialState = {
  entities: [],
  isFetching: false,
  isAdding: false,
  error: null,
};

export function selections(state = initialState, action) {
  switch (action.type) {
    // Load selections
    case LOAD_SELECTIONS:
      return Utils.assign(state, {
        isFetching: true,
      });

    // Receive selections
    case RECEIVE_SELECTIONS:
      return Utils.assign(state, {
        entities: action.selections,
        isFetching: false,
      });

    // Error receiving selections
    case RECEIVE_SELECTIONS_ERROR:
      return Utils.assign(state, {
        error: action.error,
        isFetching: false,
      });

    // Add selection
    case ADD_SELECTION:
      return Utils.assign(state, {
        isAdding: true,
        entities: [
          ...state.entities,
          {
            pk: Utils.generateUUID(),
            lever: action.lever,
            component: action.component,
            is_front: action.is_front,
            x: action.x,
            y: action.y,
            settings: action.settings,
            direction: action.direction,
          },
        ],
      });

    // Remove selection
    case REMOVE_SELECTION:
      return Utils.assign(state, {
        entities: state.entities.filter(
          (selection) => selection.pk !== action.id,
        ),
      });

    // Reset lever selections
    case RESET_LEVER_SELECTIONS:
      return Utils.assign(state, {
        entities: state.entities.filter(
          (selection) => selection.lever !== action.id,
        ),
      });

    // Pass these actions to a single entity
    case FETCH_SELECTION:
    case RECEIVE_SELECTION_ERROR:
    case CHANGE_SELECTION_SETTINGS:
    case RECEIVE_SELECTION:
    case UNSET_SELECTION_EDITING:
      return Utils.assign(state, {
        isAdding: false,
        entities: state.entities.map((selection) => entity(selection, action)),
      });

    default:
      return state;
  }
}
