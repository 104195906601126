import {
  RECEIVE_SELECTIONS,
  LOAD_SELECTIONS,
  RECEIVE_SELECTIONS_ERROR,
  ADD_SELECTION,
  FETCH_SELECTION,
  RECEIVE_SELECTION,
  RECEIVE_SELECTION_ERROR,
  REMOVE_SELECTION,
  CHANGE_SELECTION_SETTINGS,
  RESET_LEVER_SELECTIONS,
  UNSET_SELECTION_EDITING,
} from '../action-types';
import { CONDITIONAL, IS_PROJECT_EDITABLE } from '../middlewares/conditional';
import { DEBOUNCED } from '../middlewares/debounced';
import { debug } from '../helpers/debug';
import * as Utils from '../utils';

import * as NotificationActions from './notifications';
import * as PositionActions from './positions';
import * as ProjectActions from './project';
import * as LeverActions from './levers';

// Load and receive selections

function loadSelections() {
  return {
    type: LOAD_SELECTIONS,
  };
}

function receiveSelections(json) {
  return {
    type: RECEIVE_SELECTIONS,
    selections: json,
  };
}
function afterRequestSelections(json) {
  return (dispatch) => {
    dispatch(receiveSelections(json));
    dispatch(PositionActions.calculatePositions());
  };
}

function receiveSelectionsError(error) {
  return {
    type: RECEIVE_SELECTIONS_ERROR,
    error,
  };
}

export function requestSelections() {
  return (dispatch) => {
    dispatch(loadSelections());

    return Utils.ajaxCall(
      `/api/${global.AppData.uid}/selections/`,
      'GET',
      null,
      afterRequestSelections,
      receiveSelectionsError,
    );
  };
}

// Add selection

function addSelection(
  leverId,
  componentId,
  direction,
  is_front,
  x,
  y,
  settings,
) {
  return {
    type: ADD_SELECTION,
    lever: leverId,
    component: componentId,
    direction,
    is_front,
    x,
    y,
    settings,
  };
}

function dispatchReceiveSelection(json) {
  return {
    type: RECEIVE_SELECTION,
    selection: json,
  };
}

function receiveSelection(json) {
  return (dispatch) => {
    dispatch(dispatchReceiveSelection(json));
    dispatch(ProjectActions.setEditingSelection(json.pk));
  };
}

function receiveSelectionError(error) {
  return {
    type: RECEIVE_SELECTION_ERROR,
    error,
  };
}

/**
 * Post selection error
 * Display error and revert selection
 *
 * @param error
 * @return {Function}
 */
export function postSelectionError(error) {
  debug(error);
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext("Couldn't register the component"),
      }),
    );
    dispatch(requestSelections());
  };
}

/**
 * Create selection
 *
 * @param {number} leverId
 * @param {number} componentId
 * @param {string} direction
 * @param {boolean} is_front
 * @param {number} x
 * @param {number} y
 * @param {Object} settings
 * @return {Function} Thunk
 */
export function postSelection(
  leverId,
  componentId,
  direction,
  is_front,
  x,
  y,
  settings,
) {
  return {
    type: CONDITIONAL,
    meta: {
      conditional: {
        conditions: [IS_PROJECT_EDITABLE],
        callback: (dispatch) => {
          dispatch(
            addSelection(
              leverId,
              componentId,
              direction,
              is_front,
              x,
              y,
              settings,
            ),
          );
          dispatch(PositionActions.calculatePositions());

          // Create simpler settings
          const simpleSettings = settings.map((setting) => ({
            name: setting.name,
            value: setting.default,
          }));

          return Utils.ajaxCall(
            `/api/${global.AppData.uid}/selections/`,
            'POST',
            {
              lever: leverId,
              component: componentId,
              is_front,
              x,
              y,
              settings: simpleSettings,
              direction,
            },
            receiveSelection,
            postSelectionError,
          );
        },
      },
    },
  };
}

// Remove selection

function removeSelection(selectionId) {
  return {
    type: REMOVE_SELECTION,
    id: selectionId,
  };
}

// Re-calculate positions after selection is deleted
function afterDeleteSelection(response, selectionId) {
  return (dispatch) => {
    dispatch(removeSelection(selectionId));
    dispatch(PositionActions.calculatePositions());
    dispatch(LeverActions.requestLevers(false));
    dispatch(ProjectActions.resetEditingSelection(selectionId));
  };
}

export function deleteSelection(selectionId) {
  return {
    type: CONDITIONAL,
    meta: {
      conditional: {
        conditions: [IS_PROJECT_EDITABLE],
        callback: () =>
          Utils.ajaxCall(
            `/api/${global.AppData.uid}/selections/${selectionId}/`,
            'DELETE',
            null,
            afterDeleteSelection,
            receiveSelectionError,
            selectionId,
          ),
      },
    },
  };
}

// Change selection settings

function changeSelectionSettings(selectionId, name, value) {
  return {
    type: CHANGE_SELECTION_SETTINGS,
    id: selectionId,
    name,
    value,
  };
}

function fetchSelection(selectionId) {
  return {
    type: FETCH_SELECTION,
    id: selectionId,
  };
}

function afterPutSelectionSettings(json) {
  return (dispatch) => {
    dispatch(receiveSelection(json));
    dispatch(LeverActions.requestLevers(false));
  };
}

/**
 * Selection settings change error
 * Show error and reset state
 *
 * @param {string} error - Error from API.
 * @return {Function}
 */
export function putSelectionSettingsError(error) {
  debug(error);
  return (dispatch) => {
    dispatch(
      NotificationActions.addNotification({
        message: global.gettext("Couldn't change component settings"),
      }),
    );
    dispatch(requestSelections());
  };
}

export function putSelectionSettings(selectionId) {
  return (dispatch, getState) => {
    dispatch(fetchSelection(selectionId));

    const selection = Utils.getById(
      getState().selections.entities,
      selectionId,
    );

    if (!selection.isEditing) {
      return Utils.ajaxCall(
        `/api/${global.AppData.uid}/selections/${selectionId}/`,
        'PUT',
        selection,
        afterPutSelectionSettings,
        putSelectionSettingsError,
      );
    }
    return undefined;
  };
}

function dispatchUnsetEditing(selectionId) {
  return {
    type: UNSET_SELECTION_EDITING,
    id: selectionId,
  };
}
function unsetEditing(selectionId) {
  return (dispatch) => {
    dispatch(dispatchUnsetEditing(selectionId));
    dispatch(putSelectionSettings(selectionId));
  };
}
function debouncedUnsetEditing(selectionId) {
  return {
    type: DEBOUNCED,
    meta: {
      debounce: {
        time: 700,
        callback: (dispatch) => {
          dispatch(unsetEditing(selectionId));
        },
      },
    },
  };
}

/**
 * Validation functions for settings.
 *
 * Key is the setting name, value is a function that should return false if the
 * setting value is invalid.
 *
 * @type {Object}
 */
const settingValidators = {
  function: (value) => value.length <= 20,
};

/**
 * Change a selection settings
 *
 * @param {number} selectionId - The selection ID.
 * @param {string} name - Setting name.
 * @param {string|boolean} value - Setting value
 * @param {boolean} [isDelayed] - If the saving should be debounced.
 * @return {Function} Thunk
 */
export function saveSelectionSetting(
  selectionId,
  name,
  value,
  isDelayed = false,
) {
  return (dispatch) => {
    if (settingValidators[name] && !settingValidators[name](value)) {
      return undefined;
    }

    dispatch(changeSelectionSettings(selectionId, name, value));

    // Setting the editing state to false will result in a PUT
    return isDelayed
      ? dispatch(debouncedUnsetEditing(selectionId))
      : dispatch(unsetEditing(selectionId));
  };
}

// Reset selections

export function resetLeverSelections(leverId) {
  return {
    type: RESET_LEVER_SELECTIONS,
    id: leverId,
  };
}
