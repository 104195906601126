import React from 'react';
import PropTypes from 'prop-types';

import { isProjectLocked } from '../utils';
import * as constants from '../constants';

import Label from './forms/Label';
import Select from './forms/Select';

/**
 * Lever label selection component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.lever - Lever object.
 * @param {Function} props.onChange - Label select change event handler.
 */
export default function LabelSelect(props) {
  const { lever, onChange } = props;
  const id = `lever-label-${lever.pk}`;

  return (
    <div className="label-select">
      <Label htmlFor={id} text={global.gettext('Choose label')} />
      <Select
        id={id}
        value={lever.label}
        onChange={onChange}
        options={[
          {
            value: constants.BLANK,
            label: constants.LABEL_NAME[constants.BLANK],
          },
          {
            value: constants.SVAB,
            label: constants.LABEL_NAME[constants.SVAB],
          },
          {
            value: constants.CUSTOM,
            label: constants.LABEL_NAME[constants.CUSTOM],
          },
        ]}
        disabled={isProjectLocked()}
      />
    </div>
  );
}
LabelSelect.displayName = 'components/LabelSelect';
LabelSelect.propTypes = {
  lever: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
