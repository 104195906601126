import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Inline SVG icon reference component
 *
 * @param {Object} props - Component props.
 * @param {string} props.name - The icon name; file name excluding extension.
 */
export default function Icon(props) {
  const { name } = props;
  const className = classnames(`icon icon--${name}`, props.className);

  // The key is required to properly re-render if React changes the SVG in
  // place.
  //
  // Example: toggling an icon in place, the DOM will be mutated instead of
  // replaced where possible and the icon won't always be properly updated;
  // probably due to to the dangerouslySetInnerHTML value not being checked.
  //
  // http://facebook.github.io/react/docs/reconciliation.html
  return (
    <span className={className} key={name} role="presentation">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `<use xlink:href="#icon-${name}"></use>`,
        }}
      />
    </span>
  );
}
Icon.displayName = 'components/Icon';
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
Icon.defaultProps = {
  className: '',
};
