import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { isProjectLocked } from '../utils';

import NumberInput from './forms/NumberInput';
import FieldError from './forms/FieldError';
import Label from './forms/Label';

/**
 * A preview of a lever's cable output.
 *
 * @param {Array} props - Component props.
 * @param {Array} props.cables - Lever cable data.
 * @return {Object} React element.
 */
export function CablesPreview(props) {
  if (!props.cables.length) {
    return <div />;
  }

  return (
    <ol className="cables-preview">
      {props.cables.map((cable) => (
        <li key={`${cable.text}-${cable.color_name}-${cable.position_name}`}>
          <span
            className="cable-strip"
            style={{ backgroundColor: cable.color }}
          />
          {cable.position_name && (
            <span className="cable-position">
              {`(${cable.position_name}) `}
            </span>
          )}
          {cable.color_name && (
            <span className="cable-color">{`[${cable.color_name}] `}</span>
          )}
          <span className="cable-text">{`${cable.text} `}</span>
        </li>
      ))}
    </ol>
  );
}
CablesPreview.propTypes = {
  cables: PropTypes.array.isRequired,
};

/**
 * Lever cable data and length edit.
 *
 * @param {Object} props - Component props.
 * @param {number} props.leverId - Lever ID.
 * @param {Array} props.cables - Lever cable data.
 * @param {number|string} props.cableLength - The current cable length.
 * @param {Function} props.onChange - Cable length change handler. Is passed
 *   the cable length value.
 * @param {string} [props.cableLengthError] - Error text for invalid
 *   cable length.
 * @return {Object} React element.
 */
export default function Cables(props) {
  const { leverId, cables, cableLength, onChange, cableLengthError, disabled } = props;
  const hasCables = Boolean(cables && cables.length);
  const baseClass = 'cables';
  const fieldId = `length-${leverId}`;
  const fieldClass = classnames(`${baseClass}-field`, {
    [`${baseClass}-field--error`]: cableLengthError,
  });

  const value = cableLength !== '' ? parseInt(cableLength, 10) : '';

  return (
    <div className={baseClass}>
      {hasCables && (
        <React.Fragment>
          <div className={fieldClass}>
            <Label htmlFor={fieldId} text={global.gettext('Length')} />
            <NumberInput
              id={fieldId}
              isAutoSize
              value={value}
              onChange={onChange}
              disabled={isProjectLocked() || disabled}
            />
            <span className={`${baseClass}-field-unit`}>mm</span>
            {cableLengthError && <FieldError text={cableLengthError} />}
          </div>

          <CablesPreview cables={cables} />
        </React.Fragment>
      )}
      {!hasCables && (
        <p>{global.gettext('Configure the grip to view cables')}</p>
      )}
    </div>
  );
}
Cables.propTypes = {
  leverId: PropTypes.number.isRequired,
  cables: PropTypes.array.isRequired,
  cableLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onChange: PropTypes.func.isRequired,
  cableLengthError: PropTypes.string,
  disabled: PropTypes.bool,
};
Cables.defaultProps = {
  cableLengthError: '',
  disabled: false,
};
